/* eslint-disable security-node/detect-crlf */
/* eslint-disable no-console */
import { useCallback, useEffect, useState } from 'react';

import { env } from '~/env';

import { useAppContext } from '../useAppContext';
import { ApkMetadata, LocalPaymentCallbackMessage, LocalPaymentMessage } from './androidDTOs';

/**
 * Proxy to the AndroidHost, for when running inside an APK.
 */
const useAndroidHost = () => {
  const [isAPKDevice, setIsAPKDevice] = useState<boolean>(false);
  const [apkMetadata, setApkMetadata] = useState<ApkMetadata>({ appName: 'unknown', appSemVer: 'unknown' });
  const [usbDeviceNames, setUsbDeviceNames] = useState<string[]>([]);
  const [payByPinTerminalReply, setPayByPinTerminalReply] = useState<LocalPaymentCallbackMessage | null>(null);

  const {
    appContext: {
      device: { isKiosk },
    },
  } = useAppContext();

  const getApkMetadata = useCallback(() => {
    // backwards compatibility: the APK might be an older version, lacking the `getApkInfo` function
    if (!window.AndroidHost!.getApkInfo) return;
    window.AndroidHost!.getApkInfo('apkMetadata');
  }, []);

  const getUsbDeviceNames = useCallback(() => {
    // backwards compatibility: the APK might be an older version, lacking the `getApkInfo` function
    if (!window.AndroidHost!.getApkInfo) return;
    window.AndroidHost!.getApkInfo('usbDevices');
  }, []);

  const requestUsbPermission = useCallback((deviceName: string) => {
    // backwards compatibility: the APK might be an older version, lacking the `requestUsbPermission` function
    if (!window.AndroidHost!.requestUsbPermission) return;

    window.AndroidHost!.requestUsbPermission(deviceName);
  }, []);

  const printToUsb = useCallback((xmlPrintMessages: string[]): void => {
    // backwards compatibility: the APK might be an older version, lacking the `printToUsb` function
    if (!window.AndroidHost!.printToUsb) return;
    if (!xmlPrintMessages.length) return;

    window.AndroidHost!.printToUsb(xmlPrintMessages);
  }, []);

  const clearApkCache = useCallback((): void => {
    // backwards compatibility: the APK might be an older version, lacking the `clearCache` function
    if (!window.AndroidHost!.clearCache) return;

    // the APK call is blocking the thread, so when it returns the APK cache clearing has completed fully
    window.AndroidHost!.clearCache();
  }, []);

  const payByPinTerminal = useCallback(
    (localPaymentMessage: LocalPaymentMessage, devSimulation: 'off' | 'success' | 'failure'): void => {
      // fail safe: never run simulation in any environment other than dev
      if (!env.isDevelopment) devSimulation = 'off';

      if (devSimulation === 'off') {
        // No simulation: running APK-embedded is required
        if (!isAPKDevice) throw new Error('offline payment requires running APK-embedded');
      } else {
        // Simulation: either with or without APK
        if (isAPKDevice) {
          // Simulation for developing while running embedded in APK but having no PINterminal: webapp calls the APK, providing the desired `devSimulation`; APK then bypasses PINterminal invocation.
          console.log(`%c🐞Simulating ${devSimulation} payment by APK without PINterminal`, 'background-color:yellow');
        } else {
          // Simulation for developing in browser: we invoke `window.payByPinTerminalCallback` directly, bypassing the APK altogether.
          console.log(`%c🐞Simulating ${devSimulation} payment by webapp without APK`, 'background-color:yellow');

          const webappOnlySimulation = {
            paymentDuration: 3000, // for mimicing interaction User with PINTerminal
            apkCallbackMessage: {
              localPaymentMessage,
              hasSucceeded: devSimulation === 'success',
              failureInfo: devSimulation === 'success' ? null : 'simulated failure',
            },
          };

          // invoke the callback, like the APK would do
          setTimeout(() => {
            window.payByPinTerminalCallback!(btoa(JSON.stringify(webappOnlySimulation.apkCallbackMessage)));
          }, webappOnlySimulation.paymentDuration);
        }
        return; // remaining code is for APK-involvement only
      }

      // verify APK compatability
      if (!window.AndroidHost!.payByPinterminal)
        throw new Error('APK is missing the payByPinterminal function. Verify APK version.');

      // send the payment message to the APK. APK calls back to `window.payByPinTerminalCallback` (below).
      window.AndroidHost!.payByPinterminal(JSON.stringify(localPaymentMessage), devSimulation);
    },
    [isAPKDevice],
  );

  useEffect(() => {
    if (!isKiosk) return;
    if (window.AndroidHost) setIsAPKDevice(true);

    // invoked by the APK
    window.apkInfoCallback = (parameterName: string, parameterValue: string) => {
      // backwards compatibility: the APK might be an older version, lacking handling for this particular parameter
      if (parameterValue === 'unknown parameter') return;

      switch (parameterName) {
        case 'apkMetadata':
          setApkMetadata(JSON.parse(parameterValue) as ApkMetadata);
          break;
        case 'usbDevices':
          window.AndroidHost!.writeToLog(parameterValue);
          setUsbDeviceNames(JSON.parse(parameterValue) as string[]);
          break;

        default:
          throw new Error(`Android callback for unhandled parameter "${parameterName}"`);
      }
    };

    // invoked by the APK
    window.payByPinTerminalCallback = (base64SerializedMsg: string) => {
      // base64-decode and deserialize
      const localPaymentCallbackMessage = JSON.parse(atob(base64SerializedMsg)) as LocalPaymentCallbackMessage;
      // store the reply, to be picked up by the hook consumer
      setPayByPinTerminalReply(localPaymentCallbackMessage);
    };
  }, [isKiosk]);

  return {
    isAPKDevice,
    getApkMetadata,
    apkMetadata,
    getUsbDeviceNames,
    usbDeviceNames,
    requestUsbPermission,
    printToUsb,
    clearApkCache,
    payByPinTerminal,
    payByPinTerminalReply,
  };
};

export default useAndroidHost;
