import { IndexedDbRepository } from '~/utils/IndexedDbRepository';

import { LocalPaymentCallbackMessage } from '../useAndroidHost/androidDTOs';
import { OfflineKioskOrderSet } from './OfflineKioskOrderSet';

/** IndexedDb repository for OfflineKioskOrderSet storage */
export class OfflineKioskOrderSetsRepository extends IndexedDbRepository<OfflineKioskOrderSet> {
  constructor(clientIsolationSuffix: string) {
    super(`offlineBufferDb_${clientIsolationSuffix}`, 'offlineKioskOrderSets', 'id');
  }

  /** upon schema changes to the records to be stored: increase by 1 and add a migration below */
  protected expectedDbVersion = 2;

  /**
   * Migrate the actual database stored on the machine to the version this repository is expecting.
   * Note: periodically remove old migrations once we're sure they have run on each and every machine in each and every environment.
   */
  protected async applyMigration(actualDb: IDBDatabase, actualDbVersion: number): Promise<void> {
    switch (actualDbVersion) {
      case 1: // migrate from v1 to v2:
        // overhaul of order schema (TSO-666)
        await this.deleteAllRecords(actualDb); // version 1 has never gone to staging/production, so a full purge is acceptable
        break;
      case 2: // migrate from v2 to v3
        // add implementation here once needed and increase `expectedDbVersion` to 3.
        break;
      default:
        throw new Error(`No v${actualDbVersion} to v${actualDbVersion + 1} dbmigration is implemented`);
    }
  }

  /**
   * Update localPayment.status and localPayment.failureMessage for the record the `localPaymentCallbackMessage` refers to.
   * (custom update method to alleviate our business logic from repo plumbing code)
   **/
  public async updateLocalPaymentStatus(localPaymentCallbackMessage: LocalPaymentCallbackMessage): Promise<void> {
    // we make use of the fact that: offlineKioskOrderSet.id = localPaymentMessage.transactionId = finalizedOrderSet.guid
    const offlineKioskOrderSet = await this.fetchById(localPaymentCallbackMessage.localPaymentMessage.transactionId);

    offlineKioskOrderSet.localPayment.status = localPaymentCallbackMessage.hasSucceeded ? 'succeeded' : 'failed';
    offlineKioskOrderSet.localPayment.failureMessage = localPaymentCallbackMessage.hasSucceeded
      ? null
      : localPaymentCallbackMessage.failureInfo ?? 'unknown failure reason';

    await super.update(offlineKioskOrderSet);
  }

  public async fetchById(id: string): Promise<OfflineKioskOrderSet> {
    const record = await super.read(id);
    if (!record) throw new Error(`OfflineOrderSet not found in ${this.dbName} for id "${id}"`);
    return record;
  }
}
