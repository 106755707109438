import { z } from 'zod';

import { schemas } from '~/api-client/generated/swagger';

const imageObjectSchema = z.object({
  guid: z.string().nullable(),
  height: z.number(),
  width: z.number(),
  imageBase64: z.string().nullable(),
  extension: z.string(),
  mimeType: z.string(),
});

export const virtualDeviceSchema = z.object({
  id: z.number().int(),
  name: z.string().nullable(),
  bulkMode: z.boolean(),
});

export const kioskSchema = z.object({
  id: z.number().int(),
  name: z.string().nullable(),
  nameOnReceipt: z.string().nullable().optional(),
});

export const printerSchema = z.object({
  name: z.string(),
  printMode: z.enum(['cloud', 'ip', 'usb', 'local', 'windows']),
  ipAddress: z.string(),
  serialNumber: z.string(),
  // workaround: as the API does not provide a printer id (yet), a pseudoId is imposed on the schema
  pseudoId: z.string(),
});

const runtimeMode = z.enum(['regular', 'scanAndGoInAppPayment', 'scanAndGoRelayPayment']);
const scanAndGoMode = z.enum(['inAppPayment', 'relayPayment']);

export const deviceSchema = z.object({
  deviceId: z.string().nullable(),
  isKiosk: z.boolean(),
  isTemporaryAdmin: z.boolean(),
  virtualDeviceId: z.number().int().nullable(),
  selectedKiosk: kioskSchema.nullable(),
  selectedVirtualDevice: virtualDeviceSchema.nullable(),
  printers: z.array(printerSchema),
  /** The scanAndGoMode for a particular SOU as prescribed in the backend; prevails over @see clientSchema.availableScanAndGoMode. */
  prescribedScanAndGoMode: scanAndGoMode.nullable(),
});

export const startMovieSchema = z.object({
  id: z.number().int(),
  guid: z.string(),
  extension: z.string(),
  mimeType: z.string(),
});

const TimeMoment = z.object({ hour: z.number().int(), minute: z.number().int(), timeIndex: z.number().int() });
const TimeSlots = z.object({
  slotSizeMinutes: z.number().int(),
  timeOpen: TimeMoment,
  timeClose: TimeMoment,
  isToday: z.boolean(),
  dayOfWeek: z.number().int(),
  preparationMinutes: z.number().int(),
  deliveryMinutes: z.number().int(),
});
const TimeSlot = z.object({
  slotIndex: z.number().int(),
  slotSizeMinutes: z.number().int(),
  maxOrders: z.number().int(),
  count: z.number().int(),
});

const ImageData = z.object({
  id: z.number().int(),
  guid: z.string().nullable(),
  imageBase64: z.string().nullable(),
  extension: z.string(),
  mimeType: z.string(),
  height: z.number().int(),
  width: z.number().int(),
});
const PromoText = z.object({ title: z.string(), description: z.string() });
const PromoBanner = z.object({ text: z.record(PromoText), image: ImageData.nullable() });

const ContactInfo = z.object({
  clientName: z.string().nullable(),
  email: z.string().nullable(),
  address: z.string().nullable(),
  phone: z.string().nullable(),
  websiteURL: z.string().nullable(),
});

const qrWelcomeSchema = z.object({
  welcomeText: z.record(z.string()),
  termsAndConditionURLs: z.record(z.string()),
  howDoesItWorkTitle: z.record(z.string()),
  howDoesItWorkDescription: z.record(z.string()),
  no18URLs: z.record(z.string()),
});

export const clientSchema = z.object({
  refetch: z.boolean(),
  clientId: z.number().int().min(0).nullable(),
  clientGuid: z.string().nullable(),
  clientName: z.string().nullable(),
  clientSlug: z.string().nullable(),
  /** Signifies whether scanAndGo mode is "allowed", and if so: what kind.
   * @see `deviceSchema.prescribedScanAndGoMode` for the per-SOU configuration of scanAndGo mode.
   * @see `customer.runtimeMode` for the actual mode the user is running in.
   */
  availableScanAndGoMode: scanAndGoMode.nullable(),
  hasEatIn: z.boolean(),
  /** the 'regular' takeout OrderType (previously named: `hasTakeOut`), as opposed to `hasTakeOutCustom` */
  hasTakeOutRegular: z.boolean(),
  /** the user-configurable takeout OrderType, as opposed to `hasTakeOutRegular` */
  hasTakeOutCustom: z.boolean(),
  /** multilingual displayname, e.g. {"nl-NL": "Voor onderweg", "en-US": "For the road"} */
  takeOutCustomName: z.record(z.string()).nullable(),
  takeOutCustomImage: imageObjectSchema.nullable(),
  hasHomeDelivery: z.boolean(),
  hasBuyNowTakeLater: z.boolean(),
  customerIdentificationMethod: schemas.CustomerIdentificationIds,
  hasPrinter: z.boolean(),
  defaultLanguage: z.string(),
  logo: imageObjectSchema,
  receiptLogo: imageObjectSchema.nullable(),
  idleScreenBg: imageObjectSchema.nullable(),
  lockScreenBg: imageObjectSchema.nullable(),
  inactivityDurationForDialogAppearance: z.number().int().min(0),
  inactivityDurationForSessionReset: z.number().int().min(0),
  virtualDevices: z.array(virtualDeviceSchema),
  styles: z.object({
    primaryColor: z.string().nullable(),
  }),
  startMovie: startMovieSchema.nullable(),
  cultures: z.array(z.string()),
  openingTimes: z.array(TimeSlots).nullable(),
  todaysTimeSlots: z.array(TimeSlot).nullable(),
  promoBanner: PromoBanner.nullable(),
  contact: ContactInfo.optional(),
  qrWelcome: qrWelcomeSchema,
  privacyStatementURLs: z.record(z.string()),
});

export const orderTypes = schemas.OrderType;

export const EatInReferenceTypes = schemas.EatInReferenceTypes;

export const customerSchema = z.object({
  /** Signifies whether the user is running in "regular" mode or "scanAndGo" mode. @see `client.availableScanAndGoMode`. */
  runtimeMode: runtimeMode.nullable(),
  mobileUserGuid: z.string().nullable().optional(),
  selectedLanguage: z.string().nullable(),
  orderType: orderTypes.nullable(),
  // Alternative order identifier for staff or customer, e.g. table number, token/table tent number or a user input/name
  alternativeOrderIdentifier: z.string().nullable(),
  // Type of alternative order identifiers, table number, token/table tent number or user input/name
  alternativeOrderIdentifierType: EatInReferenceTypes.nullable(),
});

export const appContextSchema = z.object({
  device: deviceSchema,
  client: clientSchema,
  customer: customerSchema, // TODO: refactor to own context
});

export type Device = z.output<typeof deviceSchema>;
export type Kiosk = z.output<typeof kioskSchema>;
export type Client = z.output<typeof clientSchema>;
export type Customer = z.output<typeof customerSchema>;
export type AppContext = z.output<typeof appContextSchema>;
export type VirtualDevice = z.output<typeof virtualDeviceSchema>;
export type RuntimeMode = z.infer<typeof runtimeMode>;
export type ScanAndGoMode = z.infer<typeof scanAndGoMode>;
