import * as Sentry from '@sentry/react';
import { useSnackbar } from 'notistack';
import { FC, PropsWithChildren, SetStateAction, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Api, apiClient } from '~/api-client';
import { schemas } from '~/api-client/generated/swagger';
import { useAppContext } from '~/hooks/useAppContext';
import { useStockEvaluation } from '~/hooks/useStockEvaluation.hook';
import { notMaybe } from '~/utils/typeGuard.util';

import { ApiHeaders } from '../useAppContext/AppContext';
import { PersistenceSpecification, useBrowserPersistence } from '../useBrowserPersistence.hook';
import { Inventory, Product } from '../useInventory';
import {
  CartContext,
  CartProductGroupingInput,
  Dialog,
  ShowDialogFunction,
  TrackedFinalizedOrderSet,
  TrackStatus,
} from './Cart';
import { Cart, CartProductGrouping, cartSchema } from './cart.schema';
import { emptyCart, emptyOrder, emptyProductGrouping } from './emptyCart.constant';
import { calculateCart, calculateProductGrouping, groupingInputToGrouping, uuid } from './parseProduct.util';

/**
 * CartProvider component, all cart related logic and state, including checkout and order handling
 * All cart related dialogs are also handled here
 */
export const CartProvider: FC<PropsWithChildren> = ({ children }) => {
  /** An orderset of which the progress status (submit→pay→print) is being tracked. */
  const [trackedFinalizedOrderSet, setTrackedFinalizedOrderSet] = useState<TrackedFinalizedOrderSet>();
  const [cart, setCart] = useBrowserPersistence<Cart>(PersistenceSpecification.Cart, emptyCart, cartSchema);
  const [dialog, setDialog] = useState<Dialog | undefined>();
  const [isDialogSuppressed, setIsDialogSuppressed] = useState(false);
  const [hasAcceptedMinimumAgeCheck, setHasAcceptedMinimumAgeCheck] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { isOutOfStock } = useStockEvaluation();

  const {
    appContext: {
      device: { isKiosk },
      customer: { alternativeOrderIdentifier, alternativeOrderIdentifierType, orderType },
    },
  } = useAppContext();

  const showDialog: ShowDialogFunction = useCallback((type, options) => {
    setDialog({ [type]: options ?? true });
  }, []);

  const clearDialog = useCallback(() => {
    setDialog(undefined);
  }, []);

  const suppressDialog = useCallback((turnOn: boolean) => {
    setIsDialogSuppressed(turnOn);
  }, []);

  /** setCart wrapper to allow for side effects that need to happen when the Cart changes. */
  const setCartWrapper = useCallback(
    (action: SetStateAction<Cart>) => {
      setCart((curr) => {
        const newCart = action instanceof Function ? action(curr) : action;
        calculateCart(newCart);
        return newCart;
      });
    },
    [setCart],
  );

  // for line items with a menu use the menu product id instead of the first product id
  const getProductIdForLineItem = useCallback((lineItem: CartProductGrouping) => {
    const productIdToCheck = lineItem.menu !== null ? lineItem.menu.productId : lineItem.products[0].id;
    return productIdToCheck;
  }, []);

  const currentActiveOrder = useMemo(() => {
    const current = cart.orders[cart.currentActiveOrder];
    if (!current) throw new Error('currentActiveOrder is undefined');
    return current;
  }, [cart.currentActiveOrder, cart.orders]);

  const lineItemsByProductId = useMemo(() => {
    const map = new Map<number, CartProductGrouping[]>();
    currentActiveOrder.productGrouping.forEach((lineItem) => {
      const productId = getProductIdForLineItem(lineItem);
      const existing = map.get(productId);
      if (existing) {
        existing.push(lineItem);
      } else {
        map.set(productId, [lineItem]);
      }
    });
    return map;
  }, [currentActiveOrder.productGrouping, getProductIdForLineItem]);

  const countByProductId = useMemo((): Map<number, number> => {
    const map = new Map<number, number>();
    currentActiveOrder.productGrouping.forEach((lineItem) => {
      const productIdToCheck = getProductIdForLineItem(lineItem);
      const existing = map.get(productIdToCheck);

      if (existing) {
        map.set(productIdToCheck, existing + lineItem.count);
      } else {
        map.set(productIdToCheck, lineItem.count);
      }
    });

    return map;
  }, [currentActiveOrder.productGrouping, getProductIdForLineItem]);

  const getCountByProductId = useCallback(
    (productId: number): number => countByProductId.get(productId) ?? 0,
    [countByProductId],
  );

  /**
   * Spanning all `cart.orders`, create a map of productId → total count in the cart,
   * regardless whether the product is put in the cart as is, or as part of a Menu.
   */
  const totalCountByProductId = useMemo((): Map<number, number> => {
    const map = new Map<number, number>();

    Object.values(cart.orders).forEach((order) => {
      order.productGrouping.forEach((lineItem) => {
        lineItem.products.forEach((product) => {
          const existing = map.get(product.id);
          if (existing) {
            map.set(product.id, existing + lineItem.count);
          } else {
            map.set(product.id, lineItem.count);
          }
        });
      });
    });

    return map;
  }, [cart.orders]);

  const getTotalCountByProductId = useCallback(
    (productId: number): number => totalCountByProductId.get(productId) ?? 0,
    [totalCountByProductId],
  );

  // Toggle between now and later
  const toggleOrderType = (type?: Api.TimingOption) => {
    const newOrderType = type !== undefined ? type : cart.currentActiveOrder === 'now' ? 'later' : 'now';
    if (!cart.orders[newOrderType]) {
      // Add the new order type as it doesn't exist
      setCartWrapper((curr) => ({
        ...curr,
        currentActiveOrder: newOrderType,
        orders: {
          ...curr.orders,
          [newOrderType]: {
            ...emptyOrder,
          },
        },
      }));
    } else {
      setCartWrapper((curr) => ({
        ...curr,
        currentActiveOrder: newOrderType,
      }));
    }
  };

  const addToCart = useCallback(
    (productGrouping: CartProductGroupingInput, menuCheck = true, shouldClearDialog = true, cb?: () => void): void => {
      const adultAge = 18;
      const isSimpleProduct = !('productGrouping' in productGrouping);
      const productHasMinimumAge = isSimpleProduct ? productGrouping.simpleProduct.minimumAge >= adultAge : false;

      const newProductGrouping = isSimpleProduct
        ? { ...groupingInputToGrouping(productGrouping.simpleProduct) }
        : { ...productGrouping.productGrouping };

      const cleanupAfterAddingToCart = () => {
        cb?.(); // Call success callback if provided

        if (!shouldClearDialog) return;
        clearDialog(); // Close possible open dialog
      };

      // Handle update of existing line item
      if (newProductGrouping.hasBeenAddedToCart) {
        setCartWrapper((curr) => {
          return {
            ...curr,
            orders: {
              ...curr.orders,
              [curr.currentActiveOrder]: {
                ...curr.orders[curr.currentActiveOrder],
                productGrouping: curr.orders[curr.currentActiveOrder]?.productGrouping.map((lineItem) =>
                  lineItem.lineId === newProductGrouping.lineId ? newProductGrouping : lineItem,
                ),
              },
            },
          };
        });

        return cleanupAfterAddingToCart();
      }

      // Handle adding new line item
      if (menuCheck && newProductGrouping.isUpgradableToMenu && !newProductGrouping.hasBeenUpgradedToMenu) {
        return showDialog('make-menu', { productGrouping: newProductGrouping });
      }

      // Ensure the lineId is not the default empty lineId - it shouldn't be, but just to be sure...
      if (newProductGrouping.lineId === emptyProductGrouping.lineId) {
        newProductGrouping.lineId = uuid();
      }

      newProductGrouping.hasBeenAddedToCart = true;
      setCartWrapper((curr) => ({
        ...curr,
        orders: {
          ...curr.orders,
          [curr.currentActiveOrder]: {
            ...curr.orders[curr.currentActiveOrder],
            productGrouping: [
              ...(curr.orders[curr.currentActiveOrder]?.productGrouping ?? []),
              {
                ...newProductGrouping,
                products: newProductGrouping.products.map((product) => ({
                  ...product,
                  hasMinimumAge: productHasMinimumAge,
                })),
              },
            ],
          },
        },
      }));

      const messageTitle = t('cart.added-product-to-order', { product: newProductGrouping.products[0]?.name });

      enqueueSnackbar(messageTitle, {
        variant: 'success',
        autoHideDuration: 2000,
      });

      cleanupAfterAddingToCart();
    },

    [clearDialog, setCartWrapper, showDialog, enqueueSnackbar, t],
  );

  const incrementLineItem = useCallback(
    (cartProductGrouping?: CartProductGrouping, product?: Product): void => {
      let lineItem = cartProductGrouping;

      // if not a simple product or productId is already in cart -> show dialog
      if (product) {
        const lineItems = lineItemsByProductId.get(product.id) ?? [];

        // TO DO: Add condition for /s/ 'mode'. If so, show new dialog
        if (lineItems.length > 0 && product.type !== 'product-simple') {
          return showDialog('line-id', { productGrouping: lineItems, incrementOrDecrement: 'increment' });
        }

        lineItem = lineItems[0];
      }

      if (!lineItem) {
        throw new Error('lineItems are empty, when incrementing');
      }

      const { lineId } = lineItem;
      setCartWrapper((curr) => {
        const orderKeys: Api.TimingOption[] = Object.keys(cart.orders) as Api.TimingOption[];
        const orderType =
          orderKeys.find((orderKey) =>
            cart.orders[orderKey]!.productGrouping.some((productGroup) => productGroup.lineId === lineId),
          ) || curr.currentActiveOrder;
        return {
          ...curr,
          orders: {
            ...curr.orders,
            [orderType]: {
              ...curr.orders[orderType],
              productGrouping:
                curr.orders[orderType]?.productGrouping.map((lineItem) =>
                  lineItem.lineId === lineId ? { ...lineItem, count: lineItem.count + 1 } : lineItem,
                ) ?? [],
            },
          },
        };
      });
    },
    [lineItemsByProductId, setCartWrapper, showDialog, cart.orders],
  );

  const decrementLineItem = useCallback(
    (cartProductGrouping?: CartProductGrouping, product?: Product, shouldConfirm?: boolean): void => {
      let lineItem;

      // if not a simple product and more than one productId is in cart -> show dialog
      if (product) {
        const lineItems = lineItemsByProductId.get(product.id) ?? [];
        if (lineItems.length === 0) return;

        if (lineItems.length > 1 && product.type !== 'product-simple') {
          return showDialog('line-id', { productGrouping: lineItems, incrementOrDecrement: 'decrement' });
        }

        lineItem = lineItems[0];
      } else {
        lineItem = cartProductGrouping;
      }

      if (!lineItem) return;

      const { lineId } = lineItem;
      const isLineItemRemoved = lineItem.count === 1;

      // if lineItem has quantity of 1 -> ask for confirmation remove line item
      if (shouldConfirm && isLineItemRemoved) {
        return showDialog('confirm-delete', { productGrouping: lineItem });
      }

      setCartWrapper((curr) => {
        const orderKeys: Api.TimingOption[] = Object.keys(cart.orders) as Api.TimingOption[];
        const orderType =
          orderKeys.find((orderKey) =>
            cart.orders[orderKey]!.productGrouping.some((productGroup) => productGroup.lineId === lineId),
          ) || curr.currentActiveOrder;
        const order = curr.orders[orderType] ?? emptyOrder;
        const lineItem = order.productGrouping.find((lineItem) => lineItem.lineId === lineId);

        return {
          ...curr,
          orders: {
            ...curr.orders,
            [orderType]: {
              ...order,
              productGrouping:
                lineItem?.count === 1
                  ? order.productGrouping.filter((lineItem) => lineItem.lineId !== lineId)
                  : order.productGrouping.map((lineItem) =>
                      lineItem.lineId === lineId ? { ...lineItem, count: lineItem.count - 1 } : lineItem,
                    ),
            },
          },
        };
      });

      if (isLineItemRemoved) {
        enqueueSnackbar(t('cart.removed-product-from-order', { product: lineItem.products[0]?.name }), {
          variant: 'success',
          autoHideDuration: 2000,
        });
      }
    },

    [lineItemsByProductId, setCartWrapper, cart.orders, showDialog, enqueueSnackbar, t],
  );

  const removeLineItem = useCallback(
    (lineId: string, outOfStock?: boolean) => {
      setCartWrapper((curr) => {
        const orderKeys: Api.TimingOption[] = Object.keys(cart.orders) as Api.TimingOption[];
        const orderType =
          orderKeys.find((orderKey) =>
            cart.orders[orderKey]!.outOfStock.some((productGroup) => productGroup.lineId === lineId),
          ) || curr.currentActiveOrder;
        const order = curr.orders[orderType] ?? emptyOrder;

        return {
          ...curr,
          orders: {
            ...curr.orders,
            [orderType]: {
              ...order,
              outOfStock: outOfStock
                ? order.outOfStock.filter((lineItem) => lineItem.lineId !== lineId)
                : order.outOfStock,
              productGrouping: !outOfStock
                ? order.productGrouping.filter((lineItem) => lineItem.lineId !== lineId)
                : order.productGrouping,
            },
          },
        };
      });
    },
    [cart.orders, setCartWrapper],
  );

  const isProductOutOfStock = useCallback(
    (inventory: Inventory, productId: number) => {
      return inventory.productsById[productId] && isOutOfStock(productId);
    },
    [isOutOfStock],
  );

  const isProductIdInInventory = useCallback((inventory: Inventory, productId: number) => {
    return inventory.productsById[productId] !== undefined;
  }, []);

  const validateCart = useCallback(
    (inventory: Inventory) => {
      // if one or more products have been removed, show dialog with removed products
      // also update all prices of the products in the cart
      const removeLineItems: CartProductGrouping[] = [];
      setCartWrapper((curr) => {
        const newCart = {
          ...curr,
          orders: Object.entries(curr.orders).reduce((acc: Cart['orders'], currentOrder) => {
            const orderType = currentOrder[0] as Api.TimingOption;
            const order = currentOrder[1];

            const outOfStockProducts = order.productGrouping.filter((lineItem) =>
              lineItem.products.some((product) => isProductOutOfStock(inventory, product.id)),
            );

            acc[orderType] = {
              ...order,
              // move all lineItems with out of stock products to outOfStock
              outOfStock: [...(order.outOfStock ?? []), ...outOfStockProducts],
              productGrouping: order.productGrouping
                .map((lineItem) => {
                  // check if product is still in inventory
                  const isProductMissing = lineItem.products.some((product) => {
                    const isMissing =
                      !isProductIdInInventory(inventory, product.id) ||
                      product.supplements.some((supplement) => !isProductIdInInventory(inventory, supplement.id));
                    return isMissing;
                  });

                  if (isProductMissing) {
                    removeLineItems.push(...[lineItem]);
                    return null;
                  }

                  const isOutOfStock = lineItem.products.some((product) => isProductOutOfStock(inventory, product.id));
                  if (isOutOfStock) return null;

                  // update prices of products in lineItem
                  const productGrouping = {
                    ...lineItem,
                    menu: lineItem.menu
                      ? {
                          ...lineItem.menu,
                          price: inventory.productsById[lineItem.menu.productId].price,
                        }
                      : null,
                    products: lineItem.products.map((product) => {
                      return {
                        ...product,
                        supplements: product.supplements.map((supplement) => {
                          return {
                            ...supplement,
                            price: inventory.productsById[supplement.id].price,
                            // TODO: check if this line is correct
                            extraPrice: inventory.productsById[supplement.id].price,
                          };
                        }),
                        discountedPrice: inventory.productsById[product.id].discountedPrice ?? null,
                        depositPrice: inventory.productsById[product.id].depositPrice,
                        price: inventory.productsById[product.id].price,
                      };
                    }),
                  };
                  calculateProductGrouping(productGrouping);
                  return productGrouping;
                })
                .filter(Boolean) as CartProductGrouping[],
            };
            return acc;
          }, {}),
        };
        return newCart;
      });

      if (removeLineItems.length > 0) {
        showDialog('unavailable-products', { productGrouping: removeLineItems });
      }
    },
    [isProductIdInInventory, isProductOutOfStock, setCartWrapper, showDialog],
  );

  const addDeliveryToCart = useCallback(
    (deliveryInfo: Api.DeliveryInfo) => {
      setCartWrapper((curr) => {
        return {
          ...curr,
          orders: {
            ...curr.orders,
            [curr.currentActiveOrder]: {
              ...curr.orders[curr.currentActiveOrder],
              delivery: deliveryInfo,
            },
          },
        };
      });
    },
    [setCartWrapper],
  );

  /**
   * Stash the Cart serverside.
   * For ScanAndGo relay payment, as a concluding step on mobile before initiating payment on kiosk.
   * @returns a base64 qr-image as returned by the API, representing the relay payment token.
   */
  const stashCart = useCallback(
    async (headers: ApiHeaders) => {
      const response = await apiClient.postRelayOrder(
        { json: JSON.stringify({ ...cart, stashTimestamp: Date.now() }) },
        { headers },
      );
      if (response.error) Sentry.captureException(response.error, { extra: { cart } });
      return response.relayGuid;
    },
    [cart],
  );

  /**
   * Fetch a serverside stashed cart and import it.
   * For ScanAndGo relay payment, as initiating step on kiosk after mobile has stashed the cart.
   * @param parsedQrImage the parsed qr image (TODO s&g: replace type: use RelayPaymentQrPayload instead of string)
   */
  const importStashedCart = useCallback(
    async (cartStashGuid: string, headers: ApiHeaders) => {
      const response = await apiClient.getRelayOrder({
        queries: { relayGuid: cartStashGuid },
        headers,
      });

      const stashedCart = response.json ? cartSchema.parse(JSON.parse(response.json)) : null;

      if (!stashedCart) {
        enqueueSnackbar(t('scan-and-go.cart.stash-error'), { variant: 'danger' });
        Sentry.captureMessage('Could not import stashed cart from the API', {
          extra: { cartStashGuid },
        });
        return;
      }

      const { stashTimestamp, ...cartWithoutTimestamp } = stashedCart;

      setCartWrapper(cartWithoutTimestamp);

      return cartWithoutTimestamp;
    },
    [enqueueSnackbar, setCartWrapper, t],
  );

  /**
   * Checks if a product is in the cart by product ID.
   * @param productId - The ID of the product to search for.
   * @returns - A boolean indicating whether the product is in the cart.
   */
  const isProductIdInCart = (productId: number): boolean => {
    // Iterate through possible orders (e.g., 'now', 'later')
    for (const timingOption of Object.keys(cart.orders) as Array<keyof typeof cart.orders>) {
      const order = cart.orders[timingOption];

      // Check if the order exists
      if (order) {
        // Check the productGrouping array
        for (const grouping of order.productGrouping) {
          // Check each product in the products array
          for (const product of grouping.products) {
            if (product.id === productId) {
              return true;
            }
          }
        }
      }
    }

    return false;
  };

  /** represent the full cart contents as an `Api.Order` array, expectedly containing 1..2 orders (now/later) */
  const mapCartToApiOrders = useCallback((): Api.Order[] => {
    return Object.entries(cart.orders)
      .map(([orderKey, order]) => {
        const nonMenuProductGrouping: Api.MySupplementLineMyProductLineMySupplementLineProductGrouping = {
          count: 1,
          menu: null,
          products: order.productGrouping
            .filter((productGrouping) => productGrouping.menu === null)
            .flatMap<Api.MySupplementLineMyProductLine>((productGrouping) =>
              productGrouping.products.map(({ count, ...product }) => ({
                ...product,
                count: productGrouping.count,
              })),
            ),
        };
        const menuProductGroupings = order.productGrouping
          .map<Api.MySupplementLineMyProductLineMySupplementLineProductGrouping | undefined>(
            ({ menu, count, products }) => {
              if (!menu) return undefined;

              const priceAdjustedProducts = products.map((product) => {
                const supplements = product.supplements?.map((supplement) => ({
                  ...supplement,
                  price: supplement.extraPrice ?? 0,
                  extraPrice: supplement.extraPrice ?? 0,
                }));
                return {
                  ...product,
                  supplements,
                };
              });

              return { count, menu, products: priceAdjustedProducts };
            },
          )
          .filter(notMaybe);

        const timingOption = schemas.TimingOption.parse(orderKey);

        const delivery = order.delivery;

        // Add the alternative order identifier for the receipt (table number, token number or user name) if it exists
        // It is added to the delivery object, even though it has nothing to do with delivery because that is needed for the API..
        if (alternativeOrderIdentifier && alternativeOrderIdentifierType && delivery) {
          delivery.eatInReference = {
            type: alternativeOrderIdentifierType,
            value: alternativeOrderIdentifier,
          };
        }

        return {
          // TO DO: remove tableNumber and userName once removed from the API
          tableNumber: null,
          userName: null,
          //
          orderType: orderType ?? 'isEatIn',
          timingOption,
          productGrouping: [nonMenuProductGrouping, ...menuProductGroupings],
          delivery,
        };
      })
      .filter((order) =>
        order.productGrouping.some((grouping) => grouping.products !== null && grouping.products.length > 0),
      );
  }, [alternativeOrderIdentifier, alternativeOrderIdentifierType, cart.orders, orderType]);

  /** begin tracking of the progress status of the finalizedOrderSet (only applies to kiosk mode) */
  const startTrackingFinalizedOrderSet = useCallback(
    (finalizedOrderSet: Api.FinalizedOrderSet) => {
      if (!isKiosk) throw new Error('finalizedOrder tracking only applies to kiosk mode');
      setTrackedFinalizedOrderSet({ ...finalizedOrderSet, trackStatus: 'submitSucceeded' });
    },
    [isKiosk, setTrackedFinalizedOrderSet],
  );

  /** update the progress status of the finalizedOrder (only applies to kiosk mode) */
  const trackFinalizedOrderSet = useCallback(
    (newStatus: TrackStatus) => {
      if (!isKiosk) throw new Error('finalizedOrder tracking only applies to kiosk mode');

      setTrackedFinalizedOrderSet((prev) => (prev ? { ...prev, trackStatus: newStatus } : undefined));
    },
    [isKiosk, setTrackedFinalizedOrderSet],
  );

  /** end tracking of the progress status of the finalizedOrderSet */
  const stopTrackingFinalizedOrderSet = useCallback(() => {
    setTrackedFinalizedOrderSet(undefined);
  }, [setTrackedFinalizedOrderSet]);

  const clearCart = useCallback((): void => {
    stopTrackingFinalizedOrderSet();
    setCartWrapper(emptyCart);
    setHasAcceptedMinimumAgeCheck(false);
  }, [setCartWrapper, stopTrackingFinalizedOrderSet]);

  const minimumAge = 18;

  return (
    <CartContext.Provider
      value={{
        cart,
        countByProductId: getCountByProductId,
        totalCountByProductId: getTotalCountByProductId,
        lineItemsByProductId,
        clearCart,
        addToCart,
        addDeliveryToCart,
        incrementLineItem,
        decrementLineItem,
        dialog,
        showDialog,
        clearDialog,
        suppressDialog,
        isDialogSuppressed,
        hasAcceptedMinimumAgeCheck,
        setHasAcceptedMinimumAgeCheck,
        toggleOrderType,
        getProductIdForLineItem,
        validateCart,
        removeLineItem,
        minimumAge,
        stashCart,
        importStashedCart,
        isProductIdInCart,
        mapCartToApiOrders,
        trackedFinalizedOrderSet,
        startTrackingFinalizedOrderSet,
        trackFinalizedOrderSet,
        stopTrackingFinalizedOrderSet,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
