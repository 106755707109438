import { makeApi, Zodios, type ZodiosOptions } from '@zodios/core';
import { z } from 'zod';

const PrintOrderInfo = z.object({ orderGuid: z.string(), isRetry: z.boolean() });
const postPrint_Body = PrintOrderInfo;
const Error = z.object({ id: z.number().int(), message: z.string().nullable() });
const ResultBase = z.object({ error: Error.nullable() });
const Warning = z.object({ id: z.number().int(), message: z.string().nullable() });
const FinalizedOrderSecurity = z.object({
  clientGuid: z.string().nullable(),
  slug: z.string().nullable(),
  deviceId: z.string().nullable(),
});
const OrderType = z.enum(['isEatIn', 'isTakeAway', 'isHomeDelivery', 'isTakeOutForTheRoad']);
const TimingOption = z.enum(['now', 'later']);
const Address = z.object({
  street: z.string().nullable(),
  housenumber: z.string().nullable(),
  zipCode: z.string().nullable(),
  city: z.string().nullable(),
});
const DeliveryOptions = z.enum(['EatIn', 'TakeOut', 'Delivery', 'TakeOutForTheRoad']);
const EatInReferenceTypes = z.enum(['UserInput', 'TableNumber', 'TokenNumber']);
const EatInReferenceData = z.object({ type: EatInReferenceTypes, value: z.string().nullable() });
const DeliveryInfo = z.object({
  name: z.string().nullable(),
  eMail: z.string().nullable(),
  phone: z.string().nullable(),
  address: Address.nullable(),
  comments: z.string().nullable(),
  timeSlot: z.string().nullable(),
  deliveryOption: DeliveryOptions,
  orderReference: z.string().nullable(),
  eatInReference: EatInReferenceData.nullable(),
});
const MenuInfo = z.object({
  menuGuid: z.string().uuid().nullable(),
  productId: z.number().int(),
  price: z.number(),
  extraPrice: z.number().nullable(),
  name: z.string().nullable(),
});
const MyOrderDeposit = z.object({ id: z.number().int(), price: z.number() });
const CalculatedVAT = z.object({
  id: z.number().int(),
  percentage: z.number(),
  amount: z.number(),
  test_gross: z.number(),
});
const FinalizedSupplementLine = z.object({
  id: z.number().int(),
  name: z.string().nullable(),
  count: z.number().int(),
  price: z.number(),
  extraPrice: z.number().nullable(),
  discountedPrice: z.number().nullable(),
  takenFromProductCollectionId: z.number().int().nullable(),
  takenFromProductChoiceId: z.number().int().nullable(),
  takenFromIngredientId: z.number().int().nullable(),
  deposit: MyOrderDeposit.nullable(),
  vat: CalculatedVAT.nullable(),
  scaledPriceInMenu: z.number().nullable(),
});
const MyPreparationChoice = z.object({
  id: z.number().int(),
  name: z.string().nullable(),
  takenFromPreparationChoiceId: z.number().int(),
});
const FinalizedProductLine = z.object({
  id: z.number().int(),
  name: z.string().nullable(),
  count: z.number().int(),
  price: z.number(),
  extraPrice: z.number().nullable(),
  discountedPrice: z.number().nullable(),
  takenFromProductCollectionId: z.number().int().nullable(),
  takenFromProductChoiceId: z.number().int().nullable(),
  takenFromIngredientId: z.number().int().nullable(),
  deposit: MyOrderDeposit.nullable(),
  supplements: z.array(FinalizedSupplementLine).nullable(),
  preparationChoices: z.array(MyPreparationChoice).nullable(),
  vat: CalculatedVAT.nullable(),
  discountEventId: z.number().int().nullable(),
  scaledPriceInMenu: z.number().nullable(),
  preparationLocationId: z.number().int(),
});
const FinalizedProductLineFinalizedSupplementLineProductGrouping = z.object({
  count: z.number().int(),
  menu: MenuInfo.nullable(),
  products: z.array(FinalizedProductLine).nullable(),
});
const FinalizedOrder = z.object({
  tableNumber: z.string().nullable(),
  userName: z.string().nullable(),
  orderType: OrderType,
  timingOption: TimingOption,
  delivery: DeliveryInfo.nullable(),
  productGrouping: z.array(FinalizedProductLineFinalizedSupplementLineProductGrouping).nullable(),
  orderGuid: z.string().uuid(),
  orderNumber: z.string(),
  vats: z.array(CalculatedVAT).nullable(),
  totalToPay: z.number(),
  totalDeposit: z.number(),
  date: z.string().datetime({ offset: true }),
  textForTheRoad: z.string().nullable(),
  hasNowLater: z.boolean(),
});
const FinalizedOrderSet = z.object({
  error: Error.nullable(),
  totalPrice: z.number(),
  date: z.string().datetime({ offset: true }),
  guid: z.string().uuid(),
  warning: Warning.nullable(),
  security: FinalizedOrderSecurity.nullable(),
  finalizedOrders: z.array(FinalizedOrder).nullable(),
});
const HistoricProductLine = z.object({ count: z.number().int(), name: z.string() });
const HistoricTicket = z.object({
  productLines: z.array(HistoricProductLine),
  timingOption: TimingOption,
  orderNumber: z.string(),
});
const HistoricOrder = z.object({
  orderGuid: z.string(),
  date: z.string().datetime({ offset: true }),
  amount: z.number(),
  printcount: z.number().int(),
  orders: z.array(HistoricTicket),
});
const OrderHistory = z.object({ error: Error.nullable(), orders: z.array(HistoricOrder) });
const ImageSizes = z.enum(['full', '_100', '_200', '_300', '_400', '_500', '_600', '_800']);
const size = ImageSizes;
const DefaultSupplement = z.object({ productId: z.number().int(), count: z.number().int() });
const Ingredient = z.object({ id: z.number().int(), ingredientProductId: z.number().int() });
const ExtraPreparationChoice = z.object({ id: z.number().int(), preparationChoiceId: z.number().int() });
const ExtraProductChoice = z.object({ id: z.number().int(), productChoiceId: z.number().int() });
const ExtraProductCollection = z.object({ id: z.number().int(), productCollectionId: z.number().int() });
const ImageData = z.object({
  id: z.number().int(),
  guid: z.string().nullable(),
  imageBase64: z.string().nullable(),
  extension: z.string(),
  mimeType: z.string(),
  height: z.number().int(),
  width: z.number().int(),
});
const ProductData = z.object({
  id: z.number().int(),
  parentId: z.number().int(),
  childIds: z.array(z.number()).nullable(),
  name: z.string(),
  depositId: z.number().int().nullable(),
  vatId: z.number().int().nullable(),
  price: z.number(),
  rowversion: z.string().nullable(),
  description: z.string().nullable(),
  nutritionalValues: z.string().nullable(),
  minimumAge: z.number().int(),
  hasStock: z.boolean(),
  isInStock: z.boolean(),
  remainingStockCount: z.number().int(),
  defaultSupplements: z.array(DefaultSupplement).nullable(),
  ingredients: z.array(Ingredient).nullable(),
  preparationChoices: z.array(ExtraPreparationChoice).nullable(),
  productChoices: z.array(ExtraProductChoice).nullable(),
  productCollections: z.array(ExtraProductCollection).nullable(),
  image: ImageData.nullable(),
});
const OrderSubSectionData = z.object({
  id: z.number().int(),
  name: z.string(),
  productIds: z.array(z.number()).nullable(),
  image: ImageData.nullable(),
});
const OrderSectionData = z.object({
  id: z.number().int(),
  name: z.string().nullable(),
  image: ImageData.nullable(),
  subSections: z.array(OrderSubSectionData).nullable(),
});
const PreparationChoiceOption = z.object({ id: z.number().int(), name: z.string().nullable(), isDefault: z.boolean() });
const PreparationChoice = z.object({
  id: z.number().int(),
  name: z.string().nullable(),
  options: z.array(PreparationChoiceOption).nullable(),
});
const ProductChoiceOption = z.object({
  id: z.number().int(),
  name: z.string().nullable(),
  productId: z.number().int(),
  isDefault: z.boolean(),
  addPrice: z.number(),
});
const ProductChoice = z.object({
  id: z.number().int(),
  name: z.string().nullable(),
  options: z.array(ProductChoiceOption).nullable(),
});
const ExtraProductOrSupplement = z.object({ id: z.number().int(), productId: z.number().int(), addPrice: z.number() });
const ProductCollection = z.object({
  id: z.number().int(),
  name: z.string(),
  type: z.number().int(),
  extraProducts: z.array(ExtraProductOrSupplement).nullable(),
});
const ProductTag = z.object({
  id: z.number().int(),
  name: z.string(),
  formattingId: z.number().int(),
  formatting: z.string(),
  iconName: z.string(),
  productIds: z.array(z.number()).nullable(),
});
const Allergy = z.object({
  id: z.number().int(),
  name: z.string(),
  description: z.string(),
  iconName: z.string(),
  productIds: z.array(z.number()).nullable(),
});
const Deposit2 = z.object({ id: z.number().int(), name: z.string(), price: z.number() });
const VATData = z.object({ id: z.number().int(), percentage: z.number() });
const DiscountType = z.enum(['unknown', 'percentage', 'fixed_amount', 'free']);
const DiscountEvent = z.object({
  id: z.number().int(),
  name: z.string(),
  description: z.string(),
  type: DiscountType,
  value: z.number(),
  productIds: z.array(z.number()).nullable(),
});
const Menu = z.object({
  menuProductId: z.number().int(),
  defaultProductId: z.number().int(),
  collectionIds: z.array(z.number()).nullable(),
});
const InventoryResult = z.object({
  error: Error.nullable(),
  maxRowVersion: z.string(),
  products: z.array(ProductData),
  orderSections: z.array(OrderSectionData),
  preparationChoices: z.array(PreparationChoice).nullable(),
  productChoices: z.array(ProductChoice).nullable(),
  promoProductCollectionId: z.number().int().nullable(),
  productCollections: z.array(ProductCollection).nullable(),
  productTags: z.array(ProductTag).nullable(),
  allergies: z.array(Allergy).nullable(),
  deposits: z.array(Deposit2).nullable(),
  vats: z.array(VATData).nullable(),
  discountEvents: z.array(DiscountEvent).nullable(),
  menus: z.array(Menu).nullable(),
  allowedSearchableProductIds: z.array(z.number()),
  appVersion: z.number().int(),
  fetchDateTicksMS: z.number().int(),
  deviceId: z.string(),
});
const MainSectionData = z.object({ id: z.number().int(), name: z.string().nullable(), image: ImageData.nullable() });
const MainSections = z.object({ error: Error.nullable(), sections: z.array(MainSectionData) });
const SessionResult = z.object({ error: Error.nullable(), userGuid: z.string().nullable() });
const OrderStatuses = z.enum([
  'none',
  'created',
  'paid',
  'pending',
  'refused',
  'error',
  'failed',
  'aborted',
  'canceled',
  'refund',
  'chargeback',
  'chargebackReversed',
]);
const OrderStatusResult = z.object({ error: Error.nullable(), orderGuid: z.string().uuid(), status: OrderStatuses });
const PaymentOrderInfo = z.object({ culture: z.string(), orderGuid: z.string() });
const postPinCloud_Body = PaymentOrderInfo;
const AdyenResponseStatus = z.enum([
  'success',
  'connection_error',
  'empty_response',
  'status_rejected',
  'status_aborted',
  'invalid_event_data',
  'invalid_message_data',
  'invalid_header_data',
  'invalid_entry_mode',
  'invalid_POIReconciliationID',
  'test',
  'request_failed',
  'status_failure',
  'status_partial',
  'unknown_status',
  'invalid_terminal_name',
  'request_unauthorized',
  'client_general_exception',
  'client_exception',
]);
const AdyenResponse = z.object({
  status: AdyenResponseStatus,
  pspReference: z.string().nullable(),
  paidAmount: z.number(),
  errorMessage: z.string().nullable(),
});
const PaymentAdyenCloudResult = z.object({ error: Error.nullable(), adyenResponse: AdyenResponse.nullable() });
const AdyenPSPSessionData = z.object({ id: z.string(), data: z.string() });
const AdyenPSPErrorResponse = z.object({
  status: z.number().int(),
  errorCode: z.string(),
  message: z.string(),
  errorType: z.string(),
  pspReference: z.string(),
});
const AdyenPSPSessionResult = z.object({
  error: Error.nullable(),
  shopperReference: z.string(),
  merchantOrderReference: z.string(),
  reference: z.string(),
  session: AdyenPSPSessionData,
  adyenClientKey: z.string(),
  adyenError: AdyenPSPErrorResponse.nullable(),
});
const PSPOrderStatus = z.enum(['unhandled', 'authorised', 'pending', 'cancelled', 'refused', '_error']);
const AdyenPSPCheckStatusResult = z.object({
  error: Error.nullable(),
  status: PSPOrderStatus,
  merchantReference: z.string(),
  pspReference: z.string(),
  amount: z.number(),
  session: AdyenPSPSessionData,
  adyenError: AdyenPSPErrorResponse.nullable(),
});
const PrintMode = z.enum(['cloud', 'ip', 'usb', 'local', 'windows']);
const POSPrinterInfo = z.object({
  name: z.string(),
  printMode: PrintMode,
  ipAddress: z.string(),
  serialNumber: z.string(),
});
const POSPrintSub = z.object({ printer: POSPrinterInfo, xmlPrintMessages: z.array(z.string()).nullable() });
const POSPrints = z.object({
  error: Error.nullable(),
  printer: POSPrinterInfo,
  xmlPrintMessages: z.array(z.string()).nullable(),
  prints: z.array(POSPrintSub).nullable(),
});
const POSPrintSuccess = z.object({ orderGuid: z.string() });
const postSetprintsucces_Body = POSPrintSuccess;
const ProvisioningData = z.object({ clientId: z.number().int(), provisioningCode: z.string() });
const postProvision_Body = ProvisioningData;
const ProvisioningResult = z.object({ error: Error.nullable(), clientId: z.number().int(), deviceId: z.string() });
const RelayPostData = z.object({ json: z.string().nullable() });
const postRelayOrder_Body = RelayPostData;
const RelayPostResult = z.object({ error: Error.nullable(), relayGuid: z.string().uuid() });
const RelayGetResult = z.object({ error: Error.nullable(), guid: z.string(), json: z.string().nullable() });
const VideoData = z.object({ id: z.number().int(), guid: z.string(), extension: z.string(), mimeType: z.string() });
const PromoText = z.object({ title: z.string(), description: z.string() });
const PromoBanner = z.object({ text: z.record(PromoText), image: ImageData.nullable() });
const MobileScanAndGoOptions = z.enum(['none', 'inapp', 'qrrelay']);
const DeviceScanAndGoOptions = z.enum(['none', 'qrrelay', 'inapp', 'push']);
const ScanAndGo = z.object({
  mobile: z.array(MobileScanAndGoOptions).nullable(),
  device: z.array(DeviceScanAndGoOptions).nullable(),
});
const CustomerIdentificationIds = z.enum(['eitherWay', 'byNumber', 'byUserName']);
const VirtualDevice = z.object({ id: z.number().int(), name: z.string().nullable(), bulkMode: z.boolean() });
const Kiosk = z.object({ id: z.number().int(), name: z.string().nullable(), nameOnReceipt: z.string().nullable() });
const TimeMoment = z.object({ hour: z.number().int(), minute: z.number().int(), timeIndex: z.number().int() });
const TimeSlots = z.object({
  slotSizeMinutes: z.number().int(),
  preparationMinutes: z.number().int(),
  deliveryMinutes: z.number().int(),
  timeOpen: TimeMoment,
  timeClose: TimeMoment,
  isToday: z.boolean(),
  dayOfWeek: z.number().int(),
});
const TimeSlot = z.object({
  slotIndex: z.number().int(),
  slotSizeMinutes: z.number().int(),
  maxOrders: z.number().int(),
  count: z.number().int(),
});
const ContactInfo = z.object({
  clientName: z.string().nullable(),
  email: z.string().nullable(),
  address: z.string().nullable(),
  phone: z.string().nullable(),
  clientURL: z.string().nullable(),
});
const StartupResult = z.object({
  error: Error.nullable(),
  logo: ImageData.nullable(),
  idleScreenBg: ImageData.nullable(),
  startMovie: VideoData.nullable(),
  lockScreenBg: ImageData.nullable(),
  imageFortheroad: ImageData.nullable(),
  receiptLogo: ImageData.nullable(),
  nameFortheroad: z.record(z.string()).nullable(),
  promoBanner: PromoBanner.nullable(),
  clientId: z.number().int(),
  clientName: z.string(),
  clientGuid: z.string(),
  slug: z.string(),
  cultures: z.array(z.string()),
  countryCode: z.string(),
  culture: z.string(),
  primaryColor: z.string().nullable(),
  hasEatIn: z.boolean(),
  hasTakeOut: z.boolean(),
  hasTakeOutForTheRoad: z.boolean(),
  hasHomeDelivery: z.boolean(),
  selforderingHasBuyNowTakeLater: z.boolean(),
  timeoutToConfirmPopup: z.number().int(),
  timeoutConfirmToStartScreen: z.number().int(),
  scanAndGo: ScanAndGo.nullable(),
  customerIdentificationMethod: CustomerIdentificationIds,
  virtualDevices: z.array(VirtualDevice).nullable(),
  selectedVirtualDevice: VirtualDevice,
  selectedKiosk: Kiosk,
  printer: POSPrinterInfo.nullable(),
  printers: z.array(POSPrinterInfo).nullable(),
  openingTimes: z.array(TimeSlots).nullable(),
  todaysTimeSlots: z.array(TimeSlot).nullable(),
  welcomeTextAtTable: z.record(z.string()),
  termsAndConditionURLs: z.record(z.string()),
  privacyStatementURLs: z.record(z.string()),
  howDoesItWorkTitle: z.record(z.string()),
  howDoesItWorkDescription: z.record(z.string()),
  no18URLs: z.record(z.string()),
  contact: ContactInfo,
});
const PostStartupData = z.object({ virtualDeviceId: z.number().int() });
const postStartup_Body = PostStartupData;
const MySupplementLine = z.object({
  id: z.number().int(),
  name: z.string().nullable(),
  count: z.number().int(),
  price: z.number(),
  extraPrice: z.number().nullable(),
  discountedPrice: z.number().nullable(),
  takenFromProductCollectionId: z.number().int().nullable(),
  takenFromProductChoiceId: z.number().int().nullable(),
  takenFromIngredientId: z.number().int().nullable(),
  deposit: MyOrderDeposit.nullable(),
});
const MySupplementLineMyProductLine = z.object({
  id: z.number().int(),
  name: z.string().nullable(),
  count: z.number().int(),
  price: z.number(),
  extraPrice: z.number().nullable(),
  discountedPrice: z.number().nullable(),
  takenFromProductCollectionId: z.number().int().nullable(),
  takenFromProductChoiceId: z.number().int().nullable(),
  takenFromIngredientId: z.number().int().nullable(),
  deposit: MyOrderDeposit.nullable(),
  supplements: z.array(MySupplementLine).nullable(),
  preparationChoices: z.array(MyPreparationChoice).nullable(),
});
const MySupplementLineMyProductLineMySupplementLineProductGrouping = z.object({
  count: z.number().int(),
  menu: MenuInfo.nullable(),
  products: z.array(MySupplementLineMyProductLine).nullable(),
});
const Order = z.object({
  tableNumber: z.string().nullable(),
  userName: z.string().nullable(),
  orderType: OrderType,
  timingOption: TimingOption,
  delivery: DeliveryInfo.nullable(),
  productGrouping: z.array(MySupplementLineMyProductLineMySupplementLineProductGrouping).nullable(),
});
const OrderSet = z.object({ error: Error.nullable(), orders: z.array(Order).nullable() });
const postSubmitOrder_Body = OrderSet;
const TimeSlotSummary = z.object({
  timeslot: z.number().int(),
  count: z.number().int(),
  slotSize: z.number().int(),
  maxOrders: z.number().int(),
});
const TimeSlotsResult = z.object({
  error: Error.nullable(),
  timeslots: z.array(TimeSlotSummary),
  currentTime: z.string(),
  clientId: z.number().int(),
  kioskId: z.number().int(),
});

export const schemas = {
  PrintOrderInfo,
  postPrint_Body,
  Error,
  ResultBase,
  Warning,
  FinalizedOrderSecurity,
  OrderType,
  TimingOption,
  Address,
  DeliveryOptions,
  EatInReferenceTypes,
  EatInReferenceData,
  DeliveryInfo,
  MenuInfo,
  MyOrderDeposit,
  CalculatedVAT,
  FinalizedSupplementLine,
  MyPreparationChoice,
  FinalizedProductLine,
  FinalizedProductLineFinalizedSupplementLineProductGrouping,
  FinalizedOrder,
  FinalizedOrderSet,
  HistoricProductLine,
  HistoricTicket,
  HistoricOrder,
  OrderHistory,
  ImageSizes,
  size,
  DefaultSupplement,
  Ingredient,
  ExtraPreparationChoice,
  ExtraProductChoice,
  ExtraProductCollection,
  ImageData,
  ProductData,
  OrderSubSectionData,
  OrderSectionData,
  PreparationChoiceOption,
  PreparationChoice,
  ProductChoiceOption,
  ProductChoice,
  ExtraProductOrSupplement,
  ProductCollection,
  ProductTag,
  Allergy,
  Deposit2,
  VATData,
  DiscountType,
  DiscountEvent,
  Menu,
  InventoryResult,
  MainSectionData,
  MainSections,
  SessionResult,
  OrderStatuses,
  OrderStatusResult,
  PaymentOrderInfo,
  postPinCloud_Body,
  AdyenResponseStatus,
  AdyenResponse,
  PaymentAdyenCloudResult,
  AdyenPSPSessionData,
  AdyenPSPErrorResponse,
  AdyenPSPSessionResult,
  PSPOrderStatus,
  AdyenPSPCheckStatusResult,
  PrintMode,
  POSPrinterInfo,
  POSPrintSub,
  POSPrints,
  POSPrintSuccess,
  postSetprintsucces_Body,
  ProvisioningData,
  postProvision_Body,
  ProvisioningResult,
  RelayPostData,
  postRelayOrder_Body,
  RelayPostResult,
  RelayGetResult,
  VideoData,
  PromoText,
  PromoBanner,
  MobileScanAndGoOptions,
  DeviceScanAndGoOptions,
  ScanAndGo,
  CustomerIdentificationIds,
  VirtualDevice,
  Kiosk,
  TimeMoment,
  TimeSlots,
  TimeSlot,
  ContactInfo,
  StartupResult,
  PostStartupData,
  postStartup_Body,
  MySupplementLine,
  MySupplementLineMyProductLine,
  MySupplementLineMyProductLineMySupplementLineProductGrouping,
  Order,
  OrderSet,
  postSubmitOrder_Body,
  TimeSlotSummary,
  TimeSlotsResult,
};

export const endpoints = makeApi([
  {
    method: 'get',
    path: '/:clientId/image/:guid/:size',
    alias: 'getClientIdimageGuidSize',
    requestFormat: 'json',
    parameters: [
      {
        name: 'clientId',
        type: 'Path',
        schema: z.number().int(),
      },
      {
        name: 'guid',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'size',
        type: 'Path',
        schema: size,
      },
    ],
    response: z.unknown(),
  },
  {
    method: 'get',
    path: '/:clientId/video/:guid',
    alias: 'getClientIdvideoGuid',
    requestFormat: 'json',
    parameters: [
      {
        name: 'clientId',
        type: 'Path',
        schema: z.number().int(),
      },
      {
        name: 'guid',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.unknown(),
  },
  {
    method: 'post',
    path: '/cloudprint',
    alias: 'postCloudprint',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: postPrint_Body,
      },
      {
        name: 'secApplicationId',
        type: 'Header',
        schema: z.string(),
      },
      {
        name: 'deviceId',
        type: 'Header',
        schema: z.string(),
      },
      {
        name: 'culture',
        type: 'Query',
        schema: z.string().optional().default('nl-NL'),
      },
    ],
    response: ResultBase,
  },
  {
    method: 'get',
    path: '/finalizedOrder',
    alias: 'getFinalizedOrder',
    requestFormat: 'json',
    parameters: [
      {
        name: 'secApplicationId',
        type: 'Header',
        schema: z.string(),
      },
      {
        name: 'deviceId',
        type: 'Header',
        schema: z
          .string()
          .describe('For selfordering point, with provisioning steps, use the deviceId for security.')
          .optional(),
      },
      {
        name: 'clientGuid',
        type: 'Header',
        schema: z.string().describe('For mobile phones, use clientGuid for security.').optional(),
      },
      {
        name: 'slug',
        type: 'Header',
        schema: z.string().optional(),
      },
      {
        name: 'orderGuid',
        type: 'Query',
        schema: z.string().optional(),
      },
    ],
    response: FinalizedOrderSet,
  },
  {
    method: 'get',
    path: '/heartbeat',
    alias: 'getHeartbeat',
    requestFormat: 'json',
    parameters: [
      {
        name: 'secApplicationId',
        type: 'Header',
        schema: z.string(),
      },
      {
        name: 'deviceId',
        type: 'Header',
        schema: z.string(),
      },
    ],
    response: ResultBase,
  },
  {
    method: 'get',
    path: '/history',
    alias: 'getHistory',
    requestFormat: 'json',
    parameters: [
      {
        name: 'secApplicationId',
        type: 'Header',
        schema: z.string(),
      },
      {
        name: 'deviceId',
        type: 'Header',
        schema: z.string(),
      },
      {
        name: 'numberOfResults',
        type: 'Query',
        schema: z.number().int().describe('How many historical orders? Max 1000 lines.'),
      },
    ],
    response: OrderHistory,
  },
  {
    method: 'get',
    path: '/InventoryAll',
    alias: 'getInventoryAll',
    requestFormat: 'json',
    parameters: [
      {
        name: 'secApplicationId',
        type: 'Header',
        schema: z.string(),
      },
      {
        name: 'deviceId',
        type: 'Header',
        schema: z
          .string()
          .describe('For selfordering point, with provisioning steps, use the deviceId for security.')
          .optional(),
      },
      {
        name: 'clientGuid',
        type: 'Header',
        schema: z.string().describe('For mobile phones, use clientGuid for security.').optional(),
      },
      {
        name: 'culture',
        type: 'Header',
        schema: z.string().describe('The selected culture. Default: nl-NL.').optional().default('nl-NL'),
      },
    ],
    response: InventoryResult,
  },
  {
    method: 'get',
    path: '/InventoryMainSections',
    alias: 'getInventoryMainSections',
    requestFormat: 'json',
    parameters: [
      {
        name: 'secApplicationId',
        type: 'Header',
        schema: z.string(),
      },
      {
        name: 'deviceId',
        type: 'Header',
        schema: z.string().optional(),
      },
      {
        name: 'clientGuid',
        type: 'Header',
        schema: z.string().optional(),
      },
      {
        name: 'culture',
        type: 'Header',
        schema: z.string().optional().default('nl-NL'),
      },
    ],
    response: MainSections,
  },
  {
    method: 'get',
    path: '/mobileuser',
    alias: 'getMobileuser',
    requestFormat: 'json',
    parameters: [
      {
        name: 'secApplicationId',
        type: 'Header',
        schema: z.string(),
      },
      {
        name: 'clientGuid',
        type: 'Header',
        schema: z.string(),
      },
    ],
    response: SessionResult,
  },
  {
    method: 'get',
    path: '/orderstatus',
    alias: 'getOrderstatus',
    requestFormat: 'json',
    parameters: [
      {
        name: 'secApplicationId',
        type: 'Header',
        schema: z.string(),
      },
      {
        name: 'deviceId',
        type: 'Header',
        schema: z
          .string()
          .describe('For selfordering point, with provisioning steps, use the deviceId for security.')
          .optional(),
      },
      {
        name: 'clientGuid',
        type: 'Header',
        schema: z.string().describe('For mobile phones, use clientGuid for security.').optional(),
      },
      {
        name: 'slug',
        type: 'Header',
        schema: z.string().optional(),
      },
      {
        name: 'orderGuid',
        type: 'Query',
        schema: z.string().optional(),
      },
    ],
    response: OrderStatusResult,
  },
  {
    method: 'post',
    path: '/pinCloud',
    alias: 'postPinCloud',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `This contains the guid from a call to SubmitOrder.`,
        type: 'Body',
        schema: postPinCloud_Body,
      },
      {
        name: 'secApplicationId',
        type: 'Header',
        schema: z.string(),
      },
      {
        name: 'deviceId',
        type: 'Header',
        schema: z.string(),
      },
    ],
    response: PaymentAdyenCloudResult,
  },
  {
    method: 'delete',
    path: '/pinCloud',
    alias: 'deletePinCloud',
    requestFormat: 'json',
    parameters: [
      {
        name: 'secApplicationId',
        type: 'Header',
        schema: z.string(),
      },
      {
        name: 'deviceId',
        type: 'Header',
        schema: z.string(),
      },
      {
        name: 'adyenTerminalName',
        type: 'Header',
        schema: z.string(),
      },
      {
        name: 'txGuid',
        type: 'Header',
        schema: z.string().uuid().optional(),
      },
    ],
    response: PaymentAdyenCloudResult,
  },
  {
    method: 'get',
    path: '/pincode',
    alias: 'getPincode',
    description: `In this route, the pincode is checked. If there is a match, the device is unlocked and the API becomes accessible again.

Also see: /lock.`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'secApplicationId',
        type: 'Header',
        schema: z.string(),
      },
      {
        name: 'deviceId',
        type: 'Header',
        schema: z.string(),
      },
      {
        name: 'pinCode',
        type: 'Header',
        schema: z
          .string()
          .describe(
            "The pincode that want to check. This also keeps track of the attempts that fail and after 100 tries, it'll block the device.",
          ),
      },
    ],
    response: ResultBase,
  },
  {
    method: 'post',
    path: '/print',
    alias: 'postPrint',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: postPrint_Body,
      },
      {
        name: 'secApplicationId',
        type: 'Header',
        schema: z.string(),
      },
      {
        name: 'deviceId',
        type: 'Header',
        schema: z.string(),
      },
      {
        name: 'culture',
        type: 'Query',
        schema: z.string().optional().default('nl-NL'),
      },
    ],
    response: ResultBase,
  },
  {
    method: 'get',
    path: '/printdata',
    alias: 'getPrintdata',
    requestFormat: 'json',
    parameters: [
      {
        name: 'secApplicationId',
        type: 'Header',
        schema: z.string(),
      },
      {
        name: 'deviceId',
        type: 'Header',
        schema: z.string(),
      },
      {
        name: 'orderGuid',
        type: 'Query',
        schema: z.string(),
      },
      {
        name: 'culture',
        type: 'Query',
        schema: z.string().optional().default('nl-NL'),
      },
    ],
    response: POSPrints,
  },
  {
    method: 'post',
    path: '/provision',
    alias: 'postProvision',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `clientId: This identifies the user (like a username). Only 100 attempts are allowed per user, and then the access code is blocked ; provisioningCode: This is the access code (like a password), to request a deviceId.`,
        type: 'Body',
        schema: postProvision_Body,
      },
      {
        name: 'secApplicationId',
        type: 'Header',
        schema: z.string(),
      },
    ],
    response: ProvisioningResult,
  },
  {
    method: 'get',
    path: '/pspAdyen/checkstatus',
    alias: 'getPspAdyencheckstatus',
    requestFormat: 'json',
    parameters: [
      {
        name: 'secApplicationId',
        type: 'Header',
        schema: z.string(),
      },
      {
        name: 'deviceId',
        type: 'Header',
        schema: z
          .string()
          .describe('For selfordering point, with provisioning steps, use the deviceId for security.')
          .optional(),
      },
      {
        name: 'clientGuid',
        type: 'Header',
        schema: z.string().describe('For mobile phones, use clientGuid for security.').optional(),
      },
      {
        name: 'orderId',
        type: 'Header',
        schema: z
          .string()
          .describe(
            "On the confirm page, adyen also returns the order Id. That's the order-guid. Give us a copy of that guid.",
          ),
      },
      {
        name: 'redirectResult',
        type: 'Header',
        schema: z
          .string()
          .describe(
            'On the confirm page, adyen gives you a parameter: redirectresult. Use that code here to check the status of the payment.',
          ),
      },
      {
        name: 'culture',
        type: 'Query',
        schema: z
          .string()
          .describe('The language/region of the customer, eg nl-NL or en-US.')
          .optional()
          .default('nl-NL'),
      },
    ],
    response: AdyenPSPCheckStatusResult,
  },
  {
    method: 'get',
    path: '/pspAdyen/session',
    alias: 'getPspAdyensession',
    requestFormat: 'json',
    parameters: [
      {
        name: 'secApplicationId',
        type: 'Header',
        schema: z.string(),
      },
      {
        name: 'deviceId',
        type: 'Header',
        schema: z
          .string()
          .describe('For selfordering point, with provisioning steps, use the deviceId for security.')
          .optional(),
      },
      {
        name: 'clientGuid',
        type: 'Header',
        schema: z.string().describe('For mobile phones, use clientGuid for security.').optional(),
      },
      {
        name: 'orderGuid',
        type: 'Header',
        schema: z
          .string()
          .describe('The unique identified of the order. You have received this through the /SubmitOrder route.'),
      },
    ],
    response: AdyenPSPSessionResult,
  },
  {
    method: 'post',
    path: '/pspAdyen/webhook',
    alias: 'postPspAdyenwebhook',
    requestFormat: 'json',
    response: z.void(),
  },
  {
    method: 'post',
    path: '/relayOrder',
    alias: 'postRelayOrder',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `data.json contains a json object from the frontend, which is stored. It can later be read back using the guid that&#x27;s returned.`,
        type: 'Body',
        schema: postRelayOrder_Body,
      },
      {
        name: 'secApplicationId',
        type: 'Header',
        schema: z.string(),
      },
      {
        name: 'deviceId',
        type: 'Header',
        schema: z
          .string()
          .describe('For selfordering point, with provisioning steps, use the deviceId for security.')
          .optional(),
      },
      {
        name: 'clientGuid',
        type: 'Header',
        schema: z.string().describe('For mobile phones, use clientGuid for security.').optional(),
      },
      {
        name: 'slug',
        type: 'Header',
        schema: z.string().optional(),
      },
      {
        name: 'userGuid',
        type: 'Header',
        schema: z.string().optional(),
      },
    ],
    response: RelayPostResult,
  },
  {
    method: 'get',
    path: '/relayOrder',
    alias: 'getRelayOrder',
    requestFormat: 'json',
    parameters: [
      {
        name: 'secApplicationId',
        type: 'Header',
        schema: z.string(),
      },
      {
        name: 'deviceId',
        type: 'Header',
        schema: z
          .string()
          .describe('For selfordering point, with provisioning steps, use the deviceId for security.')
          .optional(),
      },
      {
        name: 'clientGuid',
        type: 'Header',
        schema: z.string().describe('For mobile phones, use clientGuid for security.').optional(),
      },
      {
        name: 'slug',
        type: 'Header',
        schema: z.string().optional(),
      },
      {
        name: 'relayGuid',
        type: 'Query',
        schema: z.string().describe('The guid of the relay-order'),
      },
    ],
    response: RelayGetResult,
  },
  {
    method: 'post',
    path: '/setprintsucces',
    alias: 'postSetprintsucces',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: postSetprintsucces_Body,
      },
      {
        name: 'secApplicationId',
        type: 'Header',
        schema: z.string(),
      },
      {
        name: 'deviceId',
        type: 'Header',
        schema: z.string(),
      },
    ],
    response: ResultBase,
  },
  {
    method: 'get',
    path: '/startup',
    alias: 'getStartup',
    requestFormat: 'json',
    parameters: [
      {
        name: 'secApplicationId',
        type: 'Header',
        schema: z
          .string()
          .describe(
            "This is a security-code that is specific for the app. This 'secret' is shared between the app and the api, ensuring they're in sync.",
          ),
      },
      {
        name: 'deviceId',
        type: 'Header',
        schema: z
          .string()
          .describe(
            'The id can be the guid of the client, or the friendly url name of the client. In both cases, you can get startup information (clientId and so on).',
          )
          .optional(),
      },
      {
        name: 'clientGuid',
        type: 'Header',
        schema: z.string().optional(),
      },
      {
        name: 'slug',
        type: 'Header',
        schema: z.string().optional(),
      },
    ],
    response: StartupResult,
  },
  {
    method: 'post',
    path: '/startup',
    alias: 'postStartup',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: postStartup_Body,
      },
      {
        name: 'secApplicationId',
        type: 'Header',
        schema: z.string(),
      },
      {
        name: 'deviceId',
        type: 'Header',
        schema: z.string(),
      },
    ],
    response: StartupResult,
  },
  {
    method: 'post',
    path: '/submitOrder',
    alias: 'postSubmitOrder',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: postSubmitOrder_Body,
      },
      {
        name: 'secApplicationId',
        type: 'Header',
        schema: z.string(),
      },
      {
        name: 'deviceId',
        type: 'Header',
        schema: z
          .string()
          .describe('For selfordering point, with provisioning steps, use the deviceId for security.')
          .optional(),
      },
      {
        name: 'clientGuid',
        type: 'Header',
        schema: z.string().describe('For mobile phones, use clientGuid for security.').optional(),
      },
      {
        name: 'slug',
        type: 'Header',
        schema: z.string().optional(),
      },
      {
        name: 'userGuid',
        type: 'Header',
        schema: z.string().describe('We use this to detect too many submit actions.').optional(),
      },
    ],
    response: FinalizedOrderSet,
  },
  {
    method: 'get',
    path: '/timeslots',
    alias: 'getTimeslots',
    requestFormat: 'json',
    parameters: [
      {
        name: 'secApplicationId',
        type: 'Header',
        schema: z.string(),
      },
      {
        name: 'deviceId',
        type: 'Header',
        schema: z
          .string()
          .describe('For selfordering point, with provisioning steps, use the deviceId for security.')
          .optional(),
      },
      {
        name: 'clientGuid',
        type: 'Header',
        schema: z.string().describe('For mobile phones, use clientGuid for security.').optional(),
      },
      {
        name: 'slug',
        type: 'Header',
        schema: z.string().optional(),
      },
      {
        name: 'includeDeliveryTime',
        type: 'Query',
        schema: z.boolean(),
      },
    ],
    response: TimeSlotsResult,
  },
]);

export const api = new Zodios(endpoints);

export function createApiClient(baseUrl: string, options?: ZodiosOptions) {
  return new Zodios(baseUrl, endpoints, options);
}
