import { createContext } from 'react';

import { env } from '~/env';

import { FeatureFlagKey } from './featureFlagKeys.constant';

export interface FeatureFlagsValue {
  isFeatureFlagEnabled: (key: FeatureFlagKey) => boolean;
}

function createEmptyFn<T>(fnName: string, r?: T): () => T {
  return () => {
    if (env.VITE_APP_ENV === 'development') {
      // eslint-disable-next-line no-console
      console.warn(`FeatureFlags.${fnName} called before initialized`);
    }
    return r as T;
  };
}

export const FeatureFlagsContext = createContext<FeatureFlagsValue>({
  isFeatureFlagEnabled: createEmptyFn('isFeatureFlagEnabled'), // Default implementation
});

FeatureFlagsContext.displayName = 'FeatureFlagsContext';
