/* eslint-disable no-console */
import * as Sentry from '@sentry/react';
import { enqueueSnackbar } from 'notistack';
import { FC, PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';
import { useErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';

import { Api, apiClient } from '~/api-client';
import { env, secApplicationId } from '~/env';
import { useOfflineKioskOrdering } from '~/hooks/useOfflineKioskOrdering/useOfflineKioskOrdering';
import { formatError } from '~/utils/errorHandling.util';
import { tryInvoke } from '~/utils/miscellaneous.util';

import useAndroidHost from '../useAndroidHost';
import { useAppContext } from '../useAppContext';
import { useReceiptBuilder } from '../useReceiptBuilder/useReceiptBuilder.hook';
import useScriptLoader from '../useScriptLoader.hook';
import {
  PrinterAvailabilityType,
  PrinterPoolAvailabilityType,
  PrinterPoolBusyStatusType,
} from './emptyPrinterContext.constant';
import { EposXmlGenerator } from './epsonPrinting/EposXmlGenerator';
import { EPOS_SDK_PATH, EpsonIpPrinter } from './epsonPrinting/EpsonIpPrinter';
import { PrinterContext } from './PrinterContext';

/**
 * PrinterProvider, for printing to ip/cloud/usb printer(s)
 */
export const PrinterProvider: FC<PropsWithChildren> = ({ children }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const {
    appContext,
    appContext: {
      device: { deviceId, printers: devicePrinters, selectedKiosk, selectedVirtualDevice },
    },
  } = useAppContext();

  const [printerAvailabilities, setPrinterAvailabilities] = useState<{
    [printerId: string]: PrinterAvailabilityType;
  } | null>(null);

  const [printerPoolBusyStatus, setPrinterPoolBusyStatus] = useState<PrinterPoolBusyStatusType>('idle');

  const { isAPKDevice, printToUsb } = useAndroidHost();

  const { showBoundary } = useErrorBoundary();

  const [epsonIpPrinters, setEpsonIpPrinters] = useState<EpsonIpPrinter[]>([]);

  const { buildReceiptCollection } = useReceiptBuilder();

  const { isOfflineKioskOrderingFlowActive } = useOfflineKioskOrdering();

  /**
   * Bypass handler for when VITE_DISABLE_PRINTER is set.
   * @param bypassMessage - the message to log to console when bypassing
   * @returns true if the bypass was applied
   */
  const applyDevBypass = useCallback((bypassMessage: string) => {
    if (env.VITE_DISABLE_PRINTER) {
      // eslint-disable-next-line security-node/detect-crlf
      console.log(`%c🪲VITE_DISABLE_PRINTER: ${bypassMessage}`, 'color:gray');
      return true;
    }
    return false;
  }, []);

  /**
   * For ServiceMenu users only: expose encoutered print error in a snackbar.
   */
  const showDetailedError = useCallback((error: unknown) => {
    enqueueSnackbar(`Printing failed: ${(error as Error).message ?? JSON.stringify(error)}`, {
      variant: 'danger',
      autoHideDuration: 5000,
    });
  }, []);

  // Load Epson EPOS SDK for IP printing
  const { isCompleted: isIpPrintingSdkLoaded } = useScriptLoader(appContext.device.isKiosk, EPOS_SDK_PATH, () => {
    console.info('ip printing: EPOS SDK loaded');
    if (!window.epson) throw new Error(`Loading '${EPOS_SDK_PATH}' has completed but 'window.epson' is absent`);
  });

  /**
   * The aggregated availability over the entire printer collection
   */
  const printerPoolAvailability = useMemo((): PrinterPoolAvailabilityType => {
    if (devicePrinters.length === 0) return 'emptypool'; // no printers at all
    if (printerAvailabilities === null) return 'initializing'; // during bootstrapping

    const allPrinterAvailabilities = Object.values(printerAvailabilities);

    if (allPrinterAvailabilities.some((a) => a === 'initializing')) {
      // as long as any printer is still initializing, this determines the overall availability
      return 'initializing';
    } else if (allPrinterAvailabilities.every((a) => a === 'available')) {
      // only if all printers are available, the overall status is considered available.
      return 'available';
    } else {
      // for all other cases (some available, some unavailable) the overall status is considered unavailable
      return 'unavailable';
    }
  }, [devicePrinters.length, printerAvailabilities]);

  /**
   * Get the PrinterAvailabilityType of one particular printer
   * @param printerId the (pseudo)Id of the printer
   */
  const getPrinterAvailability = useCallback(
    (printerId: string): PrinterAvailabilityType => {
      if (printerAvailabilities === null) return 'initializing'; // during bootstrapping
      return printerAvailabilities[printerId];
    },
    [printerAvailabilities],
  );

  /**
   * Fetch the print messages from the API for the specified @param orderGuid.
   * @returns: 1..n prints (for 1..n printers), each containing 1 or 2 messages (receipts).
   * @throws: when there are no non-cloud (usb/local/ip) printers.
   */
  const fetchPrints = useCallback(
    async (orderGuid: string) => {
      const data = await apiClient.getPrintdata({
        queries: { orderGuid, culture: language },
        headers: { secApplicationId, deviceId: deviceId ?? '' },
      });

      if (data.error) throw new Error(`GET PrintData returned error: ${JSON.stringify(data.error)}`);
      if (!data.prints?.length) throw new Error('GET PrintData returned no prints');

      // workaround: as the API does not provide a technical printerId (yet),
      // we append a pseudoId property here and derive its value from functional printer properties
      const prints = data.prints.map((p) => ({
        ...p,
        printerPseudoId: `${p.printer.printMode}|${p.printer.name}|${p.printer.ipAddress}|${p.printer.serialNumber}`,
      }));

      // postcondition check: all printers in the response should be present in the device printers list
      const unknownPrinters = prints
        .filter((p) => devicePrinters.findIndex((dp) => dp.pseudoId === p.printerPseudoId) === -1)
        .map((p) => p.printer);

      if (unknownPrinters.length)
        throw new Error(
          `GET PrintData returned ${unknownPrinters.length} printer(s) not in the device printers list. ` +
            `Unknown printers: ${JSON.stringify(unknownPrinters)}, known printers: ${JSON.stringify(devicePrinters)}`,
        );

      console.info(
        `fetchPrintMessages: got prints for the following ${prints.length} printer(s):\n ${prints.map((p) => `- "${p.printerPseudoId}"`).join('\n ')}`,
      );

      return prints;
    },
    [deviceId, devicePrinters, language],
  );

  /**
   * Cloud print: for the specified orderGuid, delegate the printing of receipt(s) (expected: 1 or 2) to the backend.
   * @param orderGuid - the order guid
   * @param isRetry - when printing from history this needs to be set to true
   */
  const printToCloud = useCallback(
    async (orderGuid: string, isRetry: boolean) => {
      const response = await apiClient.postPrint(
        { orderGuid, isRetry },
        { headers: { secApplicationId, deviceId: deviceId ?? '' } },
      );

      if (response.error) {
        throw new Error(`Cloud printing: POST Print returned error: ${JSON.stringify(response.error)}`);
      }
    },
    [deviceId],
  );

  /**
   * Notify the backend that the order has been printed succesfully
   * @param orderGuid: the guid of the successfully printed order
   */
  const postPrintSuccess = useCallback(
    async (orderGuid: string) => {
      const response = await apiClient.postSetprintsucces(
        { orderGuid },
        { headers: { secApplicationId, deviceId: deviceId ?? '' } },
      );
      if (response.error) Sentry.captureException(response.error, { extra: { orderGuid } });
      return response;
    },
    [deviceId],
  );

  /**
   * From the printerId, obtain the devicePrinter and if mode = ip printing: the epsonIpPrinter
   */
  const resolvePrinter = useCallback(
    (pseudoId: string) => {
      const devicePrinter = devicePrinters.find((dp) => dp.pseudoId === pseudoId);

      if (!devicePrinter) throw new Error(`No devicePrinter found having id "${pseudoId}"}`);

      if (['usb', 'local'].includes(devicePrinter.printMode)) {
        if (!isAPKDevice) {
          if (!applyDevBypass('tolerating usb-printer resolvement while not running in APK'))
            throw new Error(`resolved a usb printer while not running APK-embedded: ${pseudoId}`);
        }
      }

      const epsonIpPrinter =
        devicePrinter.printMode === 'ip'
          ? epsonIpPrinters.find((epsonIpPrinter) => epsonIpPrinter.printerId === devicePrinter.pseudoId)
          : null;

      return { devicePrinter, epsonIpPrinter };
    },
    [applyDevBypass, devicePrinters, epsonIpPrinters, isAPKDevice],
  );

  /**
   * Print a test message to the specified printer
   * @param printerId - the (pseudo)Id of the printer
   */
  const printTestMessage = useCallback(
    async (printerId: string) => {
      try {
        const deviceIdLength = 10;
        const { devicePrinter, epsonIpPrinter } = resolvePrinter(printerId);

        let xmlMessage =
          '<text>-- TESTPRINT START --</text><feed line="2"/>' +
          `<text>Sent at: ${new Date().toLocaleString('nl-NL', { hour12: false })}</text><feed line="2"/>` +
          '<text>Sent from:</text><feed line="1"/>' +
          `<text>  kiosk name: ${selectedKiosk?.name}</text><feed line="1"/>` +
          `<text>  virtual device name: ${selectedVirtualDevice?.name}</text><feed line="1"/>` +
          `<text>  virtual device id: ${selectedVirtualDevice?.id}</text><feed line="1"/>` +
          `<text>  device id: ${deviceId?.substring(0, deviceIdLength)}...</text><feed line="2"/>` +
          '<text>Sent to:</text><feed line="1"/>' +
          `<text>  ${devicePrinter.name}</text><feed line="1"/>` +
          `<text>  ${epsonIpPrinter ? epsonIpPrinter.printerIp : devicePrinter.printMode}</text><feed line="2"/>` +
          '<text>-- TESTPRINT END --</text><feed line="3"/>' +
          '<cut type="feed"/>';

        setPrinterPoolBusyStatus('printing');

        if (!applyDevBypass(`fake test message print for printerId '${printerId}':\n${xmlMessage}`)) {
          switch (devicePrinter.printMode) {
            case 'usb':
            case 'local':
              // TODO: In APK 2.3.0, usb printer could not yet handle <text> instructions
              //       In APK 2.4.0 this is fixed, but until all Prod-APK's are on 2.4.0, we stick to the current "feed only" testmessage.
              //       Once all Prod-APK's are on 2.4.0, we can remove the codeline below.
              xmlMessage = '<feed unit="3"/><cut type="feed"/>';
              printToUsb([xmlMessage]);
              break;
            case 'ip':
              await epsonIpPrinter!.print([xmlMessage]);
              break;
            case 'cloud':
              console.log('API endpoint not yet available for test print to cloud.');
              // Future refactor suggestion: ask API team to implement a cloudprint test endpoint
              // that accepts an xmlMessage (type: string).
              break;
            default:
              throw new Error(`Unexpected printMode: "${devicePrinter.printMode}"`);
          }
        }
      } catch (error) {
        // as only ServiceMenu can trigger test prints, exposing error info is allowed.
        showDetailedError(error);
      } finally {
        setPrinterPoolBusyStatus('idle');
      }
    },
    [
      deviceId,
      printToUsb,
      resolvePrinter,
      selectedKiosk?.name,
      selectedVirtualDevice?.id,
      selectedVirtualDevice?.name,
      applyDevBypass,
      showDetailedError,
    ],
  );

  /** Print the `order` in case frontend is OFFLINE */
  const printOrderOffline = useCallback(
    async (finalizedOrderSet: Api.FinalizedOrderSet) => {
      // As API is unreachable for either cloud printing or fetching prints (GET PrintData),
      // receipt(s) are manufactured locally, transformed to eposXmlMessages, and sent to 1 printer.
      const receiptCollection = buildReceiptCollection(
        finalizedOrderSet,
        /* buildOptions: */ {
          appendXtoCount: true,
          prependCurrencySymbol: false,
          omitRedundantUnitPrice: false,
        },
      );

      // for offline printing, we prefer a usb printer
      const usbPrinter = devicePrinters.find((dp) => dp.printMode === 'usb' || dp.printMode === 'local');
      if (usbPrinter) {
        const eposXmlMessages = await EposXmlGenerator.generateForUSBPrinter(receiptCollection);
        if (!applyDevBypass(`skipped offline printing order '${finalizedOrderSet.guid}' to '${usbPrinter.pseudoId}'`)) {
          printToUsb(eposXmlMessages);
        }
        return;
      }

      // in absence of a usb-printer, look for an ip-printer
      const ipPrinter = devicePrinters.find((dp) => dp.printMode === 'ip');
      if (ipPrinter) {
        const eposXmlMessages = await EposXmlGenerator.generateForIpPrinter(receiptCollection);
        const epsonIpPrinter = epsonIpPrinters.find((p) => p.printerId === ipPrinter.pseudoId);
        if (!applyDevBypass(`skipped offline printing order '${finalizedOrderSet.guid}' to '${ipPrinter.pseudoId}'`)) {
          // note: we assume the ip-printer is still connected on a LAN ip, although internet is down.
          await epsonIpPrinter!.print(eposXmlMessages);
        }
      } else {
        throw new Error('No USB or IP printer found for offline printing');
      }
    },
    [applyDevBypass, buildReceiptCollection, devicePrinters, epsonIpPrinters, printToUsb],
  );

  /** Print the `order` in case frontend is ONLINE */
  const printOrderOnline = useCallback(
    async (finalizedOrderSet: Api.FinalizedOrderSet, isReprint: boolean) => {
      // Per requirements stated by API-team, fetchPrints should not be invoked if only cloud printers are involved (API would return error);
      const hasCloudPrinters = devicePrinters.some((dp) => dp.printMode === 'cloud');
      const hasNonCloudPrinters = devicePrinters.some((dp) => dp.printMode !== 'cloud');

      // should not happen, but if it does, we want to know about it
      if (!hasCloudPrinters && !hasNonCloudPrinters) {
        throw new Error('No printers of any type (usb/local/cloud/ip) are available');
      }

      // cloudprinting is kicked-off by backend itself upon submitting an order, so frontend only kicks it off for reprints
      if (hasCloudPrinters && isReprint) {
        await printToCloud(finalizedOrderSet.guid, /* isReprint: */ true);
      }

      if (hasNonCloudPrinters) {
        const prints = await fetchPrints(finalizedOrderSet.guid);

        const printersWithPrints = prints
          .map((p) => ({
            ...resolvePrinter(p.printerPseudoId),
            xmlPrintMessages: p.xmlPrintMessages ?? [],
            isReprint,
          }))
          .filter((p) => p.devicePrinter.printMode !== 'cloud'); // cloudprinters are already dealt with in previous if-block

        for (const printer of printersWithPrints) {
          if (
            !applyDevBypass(
              `skipped online printing order '${finalizedOrderSet.guid}' to '${printer.devicePrinter.pseudoId}'`,
            )
          ) {
            switch (printer.devicePrinter.printMode) {
              case 'usb':
              case 'local':
                printToUsb(printer.xmlPrintMessages);
                break;
              case 'ip':
                // eslint-disable-next-line no-await-in-loop
                await printer.epsonIpPrinter!.print(printer.xmlPrintMessages);
                break;
              default:
                throw new Error(`Unexpected printMode: "${printer.devicePrinter.printMode}"`);
            }
          }
        }
        // inform backend (will run only if none of the prints caused any error)
        await postPrintSuccess(finalizedOrderSet.guid);
      }
    },
    [applyDevBypass, devicePrinters, fetchPrints, postPrintSuccess, printToCloud, printToUsb, resolvePrinter],
  );

  /**
   * Print the order receipt(s) (expected: 1 or 2) to 1..n printers
   * @param order - the order to print, specified as either the order or the its guid
   * @param isReprint - provide a `true` when reprinting from history (SeviceMenu)
   * @returns whether the printing succeeded without any errors
   */
  const printOrder = useCallback(
    async (finalizedOrderSet: Api.FinalizedOrderSet, isReprint: boolean = false): Promise<boolean> => {
      // tolerate absence of printers
      if (printerPoolAvailability === 'emptypool') {
        enqueueSnackbar(t('error-page.print.title'), { variant: 'danger' });
        Sentry.captureMessage('Printing is disabled, because no printer was provided in client configuration');
        return false;
      }

      try {
        setPrinterPoolBusyStatus('printing');

        if (isOfflineKioskOrderingFlowActive) {
          await printOrderOffline(finalizedOrderSet);
        } else {
          await printOrderOnline(finalizedOrderSet, isReprint);
        }
        return true;
      } catch (error) {
        // Reprinting implies the print action was initiated by a ServiceMenu user,
        // hence show what went wrong. To regular users, error info is not exposed.
        if (isReprint) {
          showDetailedError(error);
          Sentry.captureException(error, { extra: { orderGuid: finalizedOrderSet.guid } });
        } else {
          showBoundary(
            formatError('printing', {
              id: 0,
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
              message: `orderGuid: ${finalizedOrderSet.guid}, ${error.message ?? JSON.stringify(error)}`,
            }),
          );
        }
        return false;
      } finally {
        setPrinterPoolBusyStatus('idle');
      }
    },
    [
      isOfflineKioskOrderingFlowActive,
      printOrderOffline,
      printOrderOnline,
      printerPoolAvailability,
      showBoundary,
      showDetailedError,
      t,
    ],
  );

  /** Update the availability of 1 printer */
  const modifyPrinterAvailability = useCallback((printerId: string, newAvailability: PrinterAvailabilityType) => {
    setPrinterAvailabilities((prev) => ({
      ...prev,
      [printerId]: newAvailability,
    }));
  }, []);

  /**
   * Connect one ip printer
   * @param target - either an EpsonIpPrinter instance or its id
   */
  const connectIpPrinter = useCallback(
    async (target: EpsonIpPrinter | string) => {
      const epsonIpPrinter =
        target instanceof EpsonIpPrinter ? target : epsonIpPrinters.find((p) => p.printerId === target)!;

      if (applyDevBypass(`faking successful connection to ip printer '${epsonIpPrinter.printerId}'`)) {
        modifyPrinterAvailability(epsonIpPrinter.printerId, 'available');
        return;
      }

      modifyPrinterAvailability(epsonIpPrinter.printerId, 'initializing');

      try {
        const maxAttempts = 2; // retry once if the first connection attempt fails
        await tryInvoke(() => epsonIpPrinter.connect(), maxAttempts);
        modifyPrinterAvailability(epsonIpPrinter.printerId, 'available');
      } catch (error) {
        modifyPrinterAvailability(epsonIpPrinter.printerId, 'unavailable');
        Sentry.captureException(error);
      }
    },
    [applyDevBypass, epsonIpPrinters, modifyPrinterAvailability],
  );

  /**
   * Bootstrapping
   */
  useEffect(() => {
    if (!appContext.device.isKiosk) return;

    const bootstrapPrinterPool = async () => {
      // exit if no printers are configured in the backoffice at all
      if (!devicePrinters.length) return;

      // spawn the dictionary of printerAvailabilities for all of the printers.
      const availabilities: { [printerId: string]: PrinterAvailabilityType } = {};
      for (const dp of devicePrinters) {
        availabilities[dp.pseudoId] =
          // usb and cloud printers are a responsibility of the APK resp. API, hence are considered available right away.
          dp.printMode === 'ip' ? 'initializing' : 'available';
      }
      setPrinterAvailabilities(availabilities);

      // Create 0..n EsponIpPrinter instance(s), connect them and update availability states accordingly.
      const epsonPrinters = devicePrinters
        .filter((dp) => dp.printMode === 'ip')
        .map((p) => EpsonIpPrinter.Create(p.pseudoId, p.name, p.ipAddress));

      setEpsonIpPrinters(epsonPrinters);

      console.info(`ip printing: connecting ${epsonPrinters.length} EpsonIpPrinter(s)...`);
      for (const p of epsonPrinters) {
        // eslint-disable-next-line no-await-in-loop
        await connectIpPrinter(p);
      }
    };

    // Once per application lifetime: bootstrap the printer pool
    if (devicePrinters && isIpPrintingSdkLoaded && printerAvailabilities === null) {
      void bootstrapPrinterPool();
    }
  }, [appContext.device.isKiosk, connectIpPrinter, devicePrinters, isIpPrintingSdkLoaded, printerAvailabilities]);

  return (
    <PrinterContext.Provider
      value={{
        printerPoolBusyStatus,
        printerPoolAvailability,
        getPrinterAvailability,
        printOrder,
        printTestMessage,
        connectIpPrinter,
      }}
    >
      {children}
    </PrinterContext.Provider>
  );
};

PrinterProvider.displayName = 'PrinterProvider';
