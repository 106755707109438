import * as Sentry from '@sentry/react';
import { format } from 'date-fns';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';

import { Api } from '~/api-client';
import { env } from '~/env';
import { isJCA } from '~/utils/constants';

import useAndroidHost from '../useAndroidHost';
import { LocalPaymentCallbackMessage, LocalPaymentMessage } from '../useAndroidHost/androidDTOs';
import { useAppContext } from '../useAppContext';
import { useCart } from '../useCart';
import { useNetworkState } from '../useNetworkState.hook';
import { usePrinter } from '../usePrinter';
import { FinalizedOrderSetBuilder } from './FinalizedOrderSetBuilder';
import { OfflineKioskOrderSet } from './OfflineKioskOrderSet';
import { OfflineKioskOrderSetsRepository } from './OfflineKioskOrderSetsRepository';

/*
  A hook for implementation of offline ordering use cases for Kiosk, such as:
  - offline order submit
  - local payment
  - online submitting once API can be reached again.
*/
export const useOfflineKioskOrdering = () => {
  const networkState = useNetworkState();
  const { enqueueSnackbar } = useSnackbar();
  const [offlineKioskOrderSets, setOfflineKioskOrderSets] = useState<OfflineKioskOrderSet[]>([]);
  const { printOrder } = usePrinter();
  const isForcingOfflineOrderingFlow = sessionStorage.getItem('forceOfflineKioskOrderingFlow') === 'true';
  const { payByPinTerminal, payByPinTerminalReply } = useAndroidHost();

  const {
    appContext: {
      device: { deviceId, isKiosk, selectedVirtualDevice },
      client: { clientId, clientSlug },
    },
  } = useAppContext();

  const { startTrackingFinalizedOrderSet, trackFinalizedOrderSet, trackedFinalizedOrderSet } = useCart();

  /** for simulating offline ordering flow, even if API is reachable (dev/test aid) */
  const forceOfflineKioskOrderingFlow = () => {
    sessionStorage.setItem('forceOfflineKioskOrderingFlow', 'true');
  };

  const getOfflineKioskOrderSetsRepository = useCallback(
    () => new OfflineKioskOrderSetsRepository(`${clientSlug}-${selectedVirtualDevice?.id ?? 'unknown'}`),
    [clientSlug, selectedVirtualDevice?.id],
  );

  /**
   * Single point of truth regarding the decision whether 12SO frontend is to be running the offline ordering flow
   */
  const isOfflineKioskOrderingFlowActive =
    // TODO TSO-637: replace the `env.isDevelopment` evaluation by evaluation of a new appcontext-property
    // (to be filled from the response of the startup call) that signifies whether the client has enabled OfflineOrdering.
    env.isDevelopment && isKiosk && (networkState === 'offline' || isForcingOfflineOrderingFlow);

  /**
   * Construct a payment message and send it to the APK.
   * Note: although 12SO uses this pathway only when offline, it works just as well when online.
   */
  const payOfflineByPinterminal = useCallback(
    async (offlineOrderSet: OfflineKioskOrderSet) => {
      // Simulation of a 'success' or 'failure' payment in absence of a real PIN-terminal or in absence of APK altogether.
      const devPaymentSimulation = env.VITE_OFFLINE_PAYMENT_SIMULATION ? 'success' : 'off';

      // precondition checks. TODO TSO-637: verify that the errorboundary is being shown
      if (!deviceId) throw new Error('deviceId is required');
      if (['pending', 'failed'].includes(offlineOrderSet.localPayment.status) === false)
        throw new Error(`Cannot start localpayment as localPayment.status = '${offlineOrderSet.localPayment.status}'`);

      // Construct a payment message from the orderset. Property values are being set in accordance with
      // implementation in 12SO-API for the Cloud-PinTerminal flow.
      const localPaymentMessage: LocalPaymentMessage = {
        skn: isJCA(clientId) ? 'aB3dE5fG7hJ9' : 'kL8mN2oP4qR6', // consult TSO-671
        saleId: deviceId,
        timeStamp: offlineOrderSet.finalizedOrderSet.date,
        transactionId: offlineOrderSet.finalizedOrderSet.guid.toUpperCase(),
        requestedAmount: offlineOrderSet.finalizedOrderSet.totalPrice,
        serviceId: format(offlineOrderSet.finalizedOrderSet.date, 'ddHHmmssSS'),
        // TODO TSO-637: replace the values of properties below. DO NOT CHANGE THE OTHER ONES.
        terminalApiLocalEndpoint: 'https://192.168.2.3', // TODO: replace IP by actual value; keep the 'https://' prefix though (Floris heeft 23sep aan Daan gevraagd om Rob het terminal IP-adres in startup-respons mee te laten geven)
        poiId: 'P400Plus-807145543', // TODO: replace by actual value (Floris heeft 23sep aan Daan gevraagd om Rob de terminal name (e.g. 'P400Plus-807145543') in startup-respons mee te laten geven)
      };

      // update status
      offlineOrderSet.localPayment.status = 'inprogress';
      await getOfflineKioskOrderSetsRepository().update(offlineOrderSet);

      payByPinTerminal(localPaymentMessage, devPaymentSimulation);
    },
    [clientId, deviceId, getOfflineKioskOrderSetsRepository, payByPinTerminal],
  );

  /**
   * Process the reply from the PINterminal (PINterminal → APK → useAndroidHost → here).
   */
  const handlePinTerminalReply = useCallback(
    async (localPaymentCallbackMessage: LocalPaymentCallbackMessage) => {
      // TODO TSO-637: Remove this temporary snackbar stuff
      enqueueSnackbar(
        `tmp devmessage: payByPinTerminalCallback: ${localPaymentCallbackMessage.hasSucceeded ? 'GESLAAGD' : `${localPaymentCallbackMessage.failureInfo}`}`,
        { variant: localPaymentCallbackMessage.hasSucceeded ? 'success' : 'danger' },
      );

      await getOfflineKioskOrderSetsRepository().updateLocalPaymentStatus(localPaymentCallbackMessage);

      trackFinalizedOrderSet(
        localPaymentCallbackMessage.hasSucceeded ? 'pinTerminalPaymentSucceeded' : 'pinTerminalPaymentFailed',
      );

      if (!localPaymentCallbackMessage.hasSucceeded) return;

      // printing
      const isOrderPrinted = await printOrder(trackedFinalizedOrderSet!);
      if (isOrderPrinted) {
        trackFinalizedOrderSet('printingSucceeded');
      }

      // TODO TSO-637: verify failure behavior for both failed payment and failed printing
    },
    [enqueueSnackbar, getOfflineKioskOrderSetsRepository, printOrder, trackFinalizedOrderSet, trackedFinalizedOrderSet],
  );

  /**
   * Offline fallback for submitOrder
   */
  const submitKioskOrderOffline = useCallback(
    async (orders: Api.Order[]): Promise<void> => {
      enqueueSnackbar('tmp dev notification: storing offline order. See console + IndexedDb', { variant: 'info' });

      // in absence of API availability, build a FinalizedOrderSet locally
      const finalizedOrderSet = FinalizedOrderSetBuilder.build(orders);

      // start tracking the progress status (same as for online submitting)
      startTrackingFinalizedOrderSet(finalizedOrderSet);

      // spawn a new OfflineKioskOrderSet that contains the finalizedOrderSet and statusinfo, that we store in an IndexedDb
      const offlineKioskOrderSet: OfflineKioskOrderSet = {
        id: finalizedOrderSet.guid, // for primary key, use the guid of the finalizedOrderSet
        finalizedOrderSet,
        onlineSubmitting: { status: 'pending', failureMessage: null },
        localPayment: { status: 'pending', failureMessage: null },
      };

      // store the offlineKioskOrderSet
      try {
        await getOfflineKioskOrderSetsRepository().add(offlineKioskOrderSet);
      } catch (err) {
        // note: we're offline, so error won't be sent to Sentry until we're back online
        Sentry.captureException(err);
        throw new Error(
          `Failed to locally store the following order: ${JSON.stringify(offlineKioskOrderSet)}.\nInner error: ${err}`,
        );
      }

      // start local payment
      await payOfflineByPinterminal(offlineKioskOrderSet);
    },
    [enqueueSnackbar, startTrackingFinalizedOrderSet, payOfflineByPinterminal, getOfflineKioskOrderSetsRepository],
  );

  /**
   * To be invoked once API can be reached again, to submit orders that were previously submitted offline
   * @todo: placeholder method, needs to be implemented as part of TSO-570.
   */
  const submitOfflineKioskOrdersToApi = useCallback(async () => {
    // precondition check
    if (isOfflineKioskOrderingFlowActive) throw new Error('Cannot submit offline orders to API while offline');

    // TODO TSO-570:
    // 1: fetch all records from the OfflineOrderSetsRepository having onlineSubmitting.status 'pending' or 'failed'
    // 2: for each record:
    //    a: submit the "finalizedOrderSet" therein to the 12SO-API.
    //    b: update (in the repo) its onlineSubmitting.status to 'failed' or 'succeeded' (depending on the outcome; if failed, store the failure message)

    // TODO TSO-570: remove dummy promise
    return new Promise<void>((resolve) => {
      resolve();
    });
  }, [isOfflineKioskOrderingFlowActive]);

  /** Load all order sets from repo into memory state. */
  const fetchStoredOfflineKioskOrders = useCallback(async () => {
    const result = await getOfflineKioskOrderSetsRepository().readAll();
    setOfflineKioskOrderSets(result);
  }, [getOfflineKioskOrderSetsRepository]);

  /** Remove all order sets that have been successfully submitted to the API. */
  const deleteFinishedOfflineOrderSets = useCallback(async () => {
    const repository = getOfflineKioskOrderSetsRepository();
    const allOrders = await repository.readAll();
    const finishedOrders = allOrders.filter((order) => order.onlineSubmitting.status === 'succeeded');

    await Promise.all(finishedOrders.map((order) => repository.delete(order.id)));

    await fetchStoredOfflineKioskOrders();
  }, [fetchStoredOfflineKioskOrders, getOfflineKioskOrderSetsRepository]);

  /** Listen for reply from PINterminal */
  useEffect(() => {
    if (!payByPinTerminalReply) return;
    void handlePinTerminalReply(payByPinTerminalReply);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payByPinTerminalReply]); // excluded 'handlePinTerminalReply' on purpose, otherwise infinite loop

  return {
    fetchStoredOfflineKioskOrders,
    forceOfflineKioskOrderingFlow,
    isOfflineKioskOrderingFlowActive,
    submitKioskOrderOffline,
    submitOfflineKioskOrdersToApi,
    offlineKioskOrderSets,
    deleteFinishedOfflineOrderSets,
  };
};
