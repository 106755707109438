import { Api } from '~/api-client';

import { uuid } from '../useCart/parseProduct.util';

/**
 * Builds an `Api.FinalizedOrderSet` from an 'Api.Orders' array
 * Introduced for offline ordering,
 * Objective: Clientside obtain the same structure and state of an order set that would otherwise be
 * returned by the 12SO API in an online situation, in response to a SubmitOrder request.
 * Other application parts that expect an `Api.FinalizedOrderSet` can remain agnostic of the origin (API/this builder) of that object.
 */
export class FinalizedOrderSetBuilder {
  /** public static method for easy invocation */
  public static build(orders: Api.Order[]): Api.FinalizedOrderSet {
    return new FinalizedOrderSetBuilder().run(orders);
  }

  private constructor() {}

  private _finalizedOrderSet!: Api.FinalizedOrderSet;
  private _currentDateTime = new Date();

  private run(orders: Api.Order[]): Api.FinalizedOrderSet {
    this.buildFinalizedOrderSet(orders);
    return this._finalizedOrderSet;
  }

  private buildFinalizedOrderSet(orders: Api.Order[]): void {
    this._finalizedOrderSet = {
      guid: uuid().toUpperCase(),
      date: this._currentDateTime.toISOString(),
      totalPrice: 0, // is calculated below
      error: null,
      warning: null,
      security: null,
      finalizedOrders: orders.map((order: Api.Order) => {
        const finalizedOrder = this.buildFinalizedOrder(order);
        this.calculateOrderFinancials(finalizedOrder);
        return finalizedOrder;
      }),
    };

    // calculate the totalPrice
    this._finalizedOrderSet.totalPrice = this._finalizedOrderSet.finalizedOrders!.reduce((acc, curr) => {
      return acc + curr.totalToPay;
    }, 0);
  }

  private buildFinalizedOrder(order: Api.Order): Api.FinalizedOrder {
    const finalizedOrder = {
      orderGuid: uuid(),
      orderNumber: '!! TODO TSO-637 !!', // TODO TSO-637: how to generate a proper order number in offline situations???
      textForTheRoad: 'TODO', // TODO TSO-637: set the right value
      hasNowLater: true, // TODO TSO-637: set the right value
      date: this._currentDateTime.toISOString(),
      tableNumber: order.tableNumber,
      userName: order.userName,
      orderType: order.orderType,
      timingOption: order.timingOption,
      delivery: order.delivery,
      productGrouping: order.productGrouping
        ? order.productGrouping.map((group) => this.buildProductGrouping(group))
        : null,
      // calculation of financial properties below is encapsulated in `calculateOrderFinancials`
      vats: [],
      totalToPay: 0,
      totalDeposit: 0,
    };

    return finalizedOrder;
  }

  /**
   * Calculate values of the financial totals of 1 FinalizedOrder.
   * TODO TSO-637
   */
  private calculateOrderFinancials(finalizedOrder: Api.FinalizedOrder): void {
    finalizedOrder.vats = [];
    finalizedOrder.totalDeposit = 0;
    finalizedOrder.totalToPay = 9.99;
  }

  private buildProductGrouping(
    group: Api.MySupplementLineMyProductLineMySupplementLineProductGrouping,
  ): Api.FinalizedProductLineFinalizedSupplementLineProductGrouping {
    return {
      count: group.count,
      menu: group.menu ? { ...group.menu } : null,
      products: group.products ? group.products.map((product) => this.buildProductLine(product)) : null,
    };
  }

  private buildProductLine(product: Api.MySupplementLineMyProductLine): Api.FinalizedProductLine {
    const foo = {
      ...product,
      deposit: product.deposit ? { ...product.deposit } : null,
      supplements: product.supplements
        ? product.supplements.map((supplement) => ({
            ...supplement,
            deposit: supplement.deposit ? { ...supplement.deposit } : null,
            // TODO TSO-637: set VAT properties
            vat: {
              id: 0,
              percentage: -9,
              amount: -9,
              test_gross: 0,
            },
            // TODO TSO-637: what does this mean?
            scaledPriceInMenu: 0,
          }))
        : null,
      preparationChoices: product.preparationChoices,
      // TODO TSO-637: set VAT properties
      vat: {
        id: 0,
        percentage: -9,
        amount: -9,
        test_gross: 0,
      },
      // TODO TSO-637: what does discountEventId mean?
      discountEventId: 0,
      // TODO TSO-637: what does scaledPriceInMenu mean?
      scaledPriceInMenu: 0,
      // The preparationLocationId might be relevant to printing to the right printer? In offline situations we cannot access those printers anyway...
      preparationLocationId: 0,
    };
    return foo;
  }
}
