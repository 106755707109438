/**
 * A wrapper around IndexedDB to simplify usage.
 * @param T The type of the record(s) stored.
 */
export abstract class IndexedDbRepository<T> {
  /** implement in concrete subclass */
  protected abstract expectedDbVersion: number;

  protected dbName: string;
  private collectionName: string;
  private primaryKeyName: string;

  constructor(dbName: string, collectionName: string, primaryKeyName: string) {
    this.dbName = dbName;
    this.collectionName = collectionName;
    this.primaryKeyName = primaryKeyName;
  }

  /**
   * Migrate the actual database stored on the machine to the version this repository is expecting
   */
  private async migrateDatabase(actualDb: IDBDatabase, oldVersion: number) {
    // prevent concurrent migrations (for when multiple tabs are open on the same origin, or multiple React iterations rapidly firing)
    const dbLock = 'dbLock';
    if (localStorage.getItem(dbLock)) return;

    try {
      localStorage.setItem(dbLock, 'locked');

      // oldVersion of 0 means: initial creation of db
      if (oldVersion === 0) {
        actualDb.createObjectStore(this.collectionName, { keyPath: this.primaryKeyName });
        return;
      }

      // process migrations one by one in chronological order
      for (let actualDbVersion = oldVersion; actualDbVersion < this.expectedDbVersion; actualDbVersion++) {
        // eslint-disable-next-line no-console
        console.debug(`Migrating '${actualDb.name}' from version ${actualDbVersion} to ${actualDbVersion + 1}`);
        // eslint-disable-next-line no-await-in-loop
        await this.applyMigration(actualDb, actualDbVersion);
      }
    } finally {
      localStorage.removeItem(dbLock);
    }
  }

  /** implement in concrete subclass */
  protected abstract applyMigration(actualDb: IDBDatabase, actualDbVersion: number): Promise<void>;

  public async openDatabase(): Promise<IDBDatabase> {
    return new Promise((resolve, reject) => {
      // will create the database on the fly if not yet present
      const request = indexedDB.open(this.dbName, this.expectedDbVersion);

      request.onupgradeneeded = async (event) => {
        if (event.oldVersion < this.expectedDbVersion) {
          const db = (event.target as IDBOpenDBRequest).result;
          await this.migrateDatabase(db, event.oldVersion);
        }
      };

      request.onsuccess = (event) => {
        resolve((event.target as IDBOpenDBRequest).result);
      };

      request.onerror = (event) => {
        reject((event.target as IDBOpenDBRequest).error);
      };
    });
  }

  public async read(primaryKeyValue: string): Promise<T> {
    const db = await this.openDatabase();
    return new Promise((resolve, reject) => {
      const transaction = db.transaction(this.collectionName, 'readonly');
      const collection = transaction.objectStore(this.collectionName);
      const request = collection.get(primaryKeyValue);

      request.onsuccess = (event) => {
        resolve((event.target as IDBRequest).result as T);
      };

      request.onerror = (event) => {
        reject((event.target as IDBRequest).error);
      };
    });
  }

  public async readAll(): Promise<T[]> {
    const db = await this.openDatabase();
    return new Promise((resolve, reject) => {
      const transaction = db.transaction(this.collectionName, 'readonly');
      const collection = transaction.objectStore(this.collectionName);
      const request = collection.getAll();

      request.onsuccess = (event) => {
        resolve((event.target as IDBRequest).result as T[]);
      };

      request.onerror = (event) => {
        reject((event.target as IDBRequest).error);
      };
    });
  }

  public async add(record: T): Promise<string> {
    const db = await this.openDatabase();
    return new Promise((resolve, reject) => {
      const transaction = db.transaction(this.collectionName, 'readwrite');
      const collection = transaction.objectStore(this.collectionName);
      const request = collection.add(record);

      request.onsuccess = () => {
        resolve('Record added successfully');
      };

      request.onerror = (event) => {
        reject((event.target as IDBRequest).error);
      };
    });
  }

  public async update(record: T): Promise<string> {
    const db = await this.openDatabase();
    return new Promise((resolve, reject) => {
      const transaction = db.transaction(this.collectionName, 'readwrite');
      const collection = transaction.objectStore(this.collectionName);
      const request = collection.put(record);

      request.onsuccess = () => {
        resolve('Record updated successfully');
      };

      request.onerror = (event) => {
        reject((event.target as IDBRequest).error);
      };
    });
  }

  public async delete(primaryKeyValue: string): Promise<string> {
    const db = await this.openDatabase();
    return new Promise((resolve, reject) => {
      const transaction = db.transaction(this.collectionName, 'readwrite');
      const collection = transaction.objectStore(this.collectionName);
      const request = collection.delete(primaryKeyValue);

      request.onsuccess = () => {
        resolve('Record deleted successfully');
      };

      request.onerror = (event) => {
        reject((event.target as IDBRequest).error);
      };
    });
  }

  /** Delete all records contains within the collection. The collection itself remains. */
  protected async deleteAllRecords(db: IDBDatabase): Promise<string> {
    return new Promise((resolve, reject) => {
      const transaction = db.transaction(this.collectionName, 'readwrite');
      const collection = transaction.objectStore(this.collectionName);
      const request = collection.clear();

      request.onsuccess = () => {
        resolve('Records deleted successfully');
      };

      request.onerror = (event) => {
        reject((event.target as IDBRequest).error);
      };
    });
  }
}
